import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Button,
  Popover,
  Typography
} from '@material-ui/core';
import TopBar from './TopBar';
import Page from '../../components/Page';
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      borderRight: '100px solid white',
      borderLeft: '100px solid white'
    }
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    width: '90%'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  navigationButtonText: {
    fontFamily: 'Poppins-Regular',
    fontSize: 24.67
  },
  footer: {
    color: 'white',
    fontSize: 25
  },
  socialMediaIcons: {
    color: 'white'
  },
  footerFirstBlock: {
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      fontSize: '0.75rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8rem',
      padding: 60,
      paddingLeft: 140
    }
  },
  footerSecondBlock: {
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      fontSize: '0.75rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8rem',
      padding: 60,
      paddingLeft: 140
    }
  },
  footerThirdBlock: {
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      fontSize: '0.75rem'
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      fontSize: '0.8rem',
      bottom: 60,
    }
  }
}));

const applicationNavigationButtons = [
  { name: 'MY ACCOUNT', route: '/account' },
  { name: 'DASHBOARD', route: '/dashboard' },
  { name: 'BIDS', route: '/bids' },
  { name: 'RFQ', route: '/rfq' },
  { name: 'BILLING', route: '/billing', disabled: true },
  { name: 'TEAMS', route: '/teams', disabled: false },
  { name: 'WEBSITE', route: '/website', disabled: true }
];

const MainLayout = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'admin-popover' : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <TopBar />
            {/*<Grid*/}
            {/*  container*/}
            {/*  xs={12}*/}
            {/*  justify="space-evenly"*/}
            {/*  alignItems="center"*/}
            {/*  style={{ backgroundColor: 'rgb(240, 243, 244)' }}*/}
            {/*>*/}
            {/*  {applicationNavigationButtons.map(({ name, route, disabled }) => (*/}
            {/*    <Grid>*/}
            {/*      <Button*/}
            {/*        disabled={disabled}*/}
            {/*        className={classes.navigationButtonText}*/}
            {/*        onClick={() => navigate(route)}*/}
            {/*      >*/}
            {/*        {name}*/}
            {/*      </Button>*/}
            {/*    </Grid>*/}
            {/*  ))}*/}
            {/*  {*/}
            {/*    <>*/}
            {/*      <Grid>*/}
            {/*        <Button*/}
            {/*          className={classes.navigationButtonText}*/}
            {/*          onClick={handleClick}*/}
            {/*        >*/}
            {/*          {'ADMIN'}*/}
            {/*        </Button>*/}
            {/*      </Grid>*/}
            {/*      <Popover*/}
            {/*        id={id}*/}
            {/*        open={open}*/}
            {/*        anchorEl={anchorEl}*/}
            {/*        onClose={handleClose}*/}
            {/*        anchorOrigin={{*/}
            {/*          vertical: 'bottom',*/}
            {/*          horizontal: 'left'*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <Grid>*/}
            {/*          <Button onClick={() => navigate('admin/customers')}>*/}
            {/*            {'Customers List'}*/}
            {/*          </Button>*/}
            {/*        </Grid>*/}
            {/*        <Grid>*/}
            {/*          <Button onClick={() => navigate('admin/bids')}>*/}
            {/*            {'Bids List'}*/}
            {/*          </Button>*/}
            {/*        </Grid>*/}
            {/*      </Popover>*/}
            {/*    </>*/}
            {/*  }*/}
            {/*</Grid>*/}
            <Outlet />
            <Grid
              xs={12}
              container
              direction="row"
              style={{ backgroundColor: 'rgb(32, 76, 106)' }}
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid xs={11} container>
                <Grid xs={12} md={5} item className={classes.footerFirstBlock}>
                  <Typography className={classes.footer}>
                    Get in touch
                  </Typography>
                  <Typography className={classes.footer}>
                    Vendorbay, Inc.
                  </Typography>
                  <Typography className={classes.footer}>
                    129 Apo St., Sta Mesa Heights
                  </Typography>
                  <Typography className={classes.footer}>
                    Quezon City,1100,Metro Manila,
                  </Typography>
                  <Typography className={classes.footer}>
                    Philippines
                  </Typography>
                  <Typography className={classes.footer}>
                    02-8-000-0000
                  </Typography>
                </Grid>

                <Grid xs={12} md={5} item className={classes.footerSecondBlock}>
                  <Typography className={classes.footer}>
                    INFORMATION
                  </Typography>
                  <Typography className={classes.footer}>FAQs</Typography>
                  <Typography className={classes.footer}>Payment</Typography>
                  <Typography className={classes.footer}>Delivery</Typography>
                  <Typography className={classes.footer}>
                    Security and Privacy Policy
                  </Typography>
                  <Typography className={classes.footer}>
                    Terms and Conditions
                  </Typography>
                </Grid>

                <Grid xs={12} md={2} style={{ position: 'relative' }}>
                  <Grid className={classes.footerThirdBlock}>
                    <IconButton
                      type="submit"
                      className={classes.socialMediaIcons}
                      aria-label="search"
                    >
                      <InstagramIcon />
                    </IconButton>
                    <IconButton
                      type="submit"
                      className={classes.socialMediaIcons}
                      aria-label="search"
                    >
                      <FacebookIcon />
                    </IconButton>
                    <IconButton
                      type="submit"
                      className={classes.socialMediaIcons}
                      aria-label="search"
                    >
                      <TwitterIcon />
                    </IconButton>
                    <IconButton
                      type="submit"
                      className={classes.socialMediaIcons}
                      aria-label="search"
                    >
                      <WhatsAppIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

import React, { useEffect } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  isLoaded,
  isEmpty,
  useFirebase,
  useFirestoreConnect
} from 'react-redux-firebase';
import Lottie from 'react-lottie';
// import loadingAnimation from '../assets/lottie/loading_animation';
import isEmptyLodash from 'lodash/isEmpty';
import animationData from '../assets/lottie/loading_animation.json';

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ path, component: Component }) => {
  const firebase = useFirebase();
  const auth = useSelector(state => state.firebase.auth);
  const location = useLocation();

  useFirestoreConnect(() => [`Users/${auth.uid}`]);

  const user = useSelector(
    ({ firestore: { data } }) => data.Users && data.Users[auth.uid]
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged(authUser => {
      console.log(authUser);
      // authUser
      //   ? setAuthUser(authUser)
      //   : setAuthUser(null);
    });
    return () => {
      unlisten();
    };
  });

  const isAccountComplete = () => {
    if (!user) return false;
    return !(
      !user.lastName ||
      !user.firstName ||
      !user.middleInitial ||
      !user.company ||
      !user.designation ||
      !user.companyAddress ||
      !user.contactNumber
    );
  };

  console.log(isEmptyLodash(undefined));

  const isProfileComplete = () => {
    if (!user) return false;

    return !(
      !user.companyName ||
      !user.taxIdentificationNumber ||
      !user.lineOfBusiness ||
      !user.lineOfBusiness ||
      !user.categories ||
      !user.brandCarried ||
      !user.locationsServed ||
      !user.companyAddress ||
      isEmptyLodash(user.officialReceipt) ||
      isEmptyLodash(user.birCertificate) ||
      isEmptyLodash(user.proofOfAccountOwnership) ||
      isEmptyLodash(user.catalogsAndPricelists)
    );
  };

  if (!isLoaded(auth) && !isLoaded(user)) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  if (isLoaded(auth) && !isEmpty(auth)) {
    if (!!user) {
      // if (!user.approved) {
      if ((!location.pathname.includes('account') && !location.pathname.includes('complete-profile')) && !isAccountComplete()) {
        return <Navigate to="/account" />;
      }
      if (
        (!location.pathname.includes('account') && !location.pathname.includes('complete-profile')) &&
        !isProfileComplete()
      ) {
        return <Navigate to="/complete-profile" />;
      }

      if (
        (!location.pathname.includes('account') && !location.pathname.includes('complete-profile') &&  !location.pathname.includes('verification-pending') ) &&
        !user.approved
      ) {
        return <Navigate to="/verification-pending" />;
      }
      // }
    }

    return <Route path={path} element={<Component />} />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;

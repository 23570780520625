import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import get from 'lodash/get';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
  Container,
} from '@material-ui/core';
import { AttachFile as AttachFileIcon } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import ChipInput from 'material-ui-chip-input';
import { useCompleteProfile } from './completeProfile.hooks';
import 'react-phone-input-2/lib/material.css';
import Page from '../../../components/Page';
import UPLOAD_ICON from '../../../assets/account/UPLOAD_ICON.png';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  componentTitleBox: {
    marginBottom: 50
  },
  componentTitle: {
    fontSize: 16
  },
  createdAt: {
    color: '#959AA5',
    marginBottom: 10
  },
  projectTitle: {
    fontSize: 24
  },
  projectDetailsBox: {
    paddingTop: 50
  },
  projectDetailInput: {
    paddingBottom: 20
  },
  container: {
    padding: 50
  },
  label: {
    fontSize: 13
  },
  labelContainer: {
    paddingBottom: 10
  },
  documentRequirementContainer: {
    border: '1px solid #EDF2F9',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  addNewProductButton: {
    background: '#9ECAB5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  buttonLabel: {
    color: 'white'
  },
  buttonIcon: { color: 'white', marginRight: 10 },
  submitButton: {
    background: '#9FC6DD',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
    borderRadius: 4,
    height: 50.22
  },
  productImage: { width: 161, height: 161 }
}));

const Form = ({}) => {
  const classes = useStyles();

  const {
    getRegions,
    handleSubmit,
    handleInputChange,
    handleFileChange,
    handleNumberChange,
    files,
    inputs
  } = useCompleteProfile();

  return (
    <Page
      className={classes.root}
      title="Account"
      style={{ backgroundColor: 'white' }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item md={6} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid xs={12} container className={classes.projectDetailsBox}>
                  <Grid xs={12} md={12} className={classes.projectDetailInput}>
                    <Typography variant="h4">COMPANY DETAILS</Typography>
                  </Grid>
                </Grid>
                {/* <Grid xs={12} container> */}
                {/*  <Grid xs={12} className={classes.projectDetailInput}> */}
                {/*    <Select */}
                {/*      variant="outlined" */}
                {/*      label="Business Type" */}
                {/*      name="businessType" */}
                {/*      onChange={handleInputChange} */}
                {/*      value={inputs.businessType} */}
                {/*      fullWidth */}
                {/*      style={{ width: '100%' }} */}
                {/*    > */}
                {/*      <MenuItem value="service">Service</MenuItem> */}
                {/*      <MenuItem value="product">Product</MenuItem> */}
                {/*      <MenuItem value="all">All</MenuItem> */}
                {/*    </Select> */}
                {/*  </Grid> */}
                {/* </Grid> */}

                <Grid xs={12} container>
                  <Grid xs={12} className={classes.projectDetailInput}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Company Name (as it appears on BIR documents)
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="companyName"
                      onChange={handleInputChange}
                      value={inputs.companyName || ''}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} container>
                  <Grid xs={12} className={classes.projectDetailInput}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Tax Identification Number
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="taxIdentificationNumber"
                      onChange={handleInputChange}
                      value={inputs.taxIdentificationNumber || ''}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} container>
                  <Grid xs={12} className={classes.projectDetailInput}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Line of Business - DTI and BIR declared
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="lineOfBusiness"
                      onChange={handleInputChange}
                      value={inputs.lineOfBusiness || ''}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} container>
                  <Grid xs={12} className={classes.projectDetailInput}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Categories - email help@vendoerbay for changes
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="categories"
                      onChange={handleInputChange}
                      value={inputs.categories || ''}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} container>
                  <Grid xs={12} className={classes.projectDetailInput}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Brands Carried - Manufacturer, Distributor, Retailer
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="brandCarried"
                      onChange={handleInputChange}
                      value={inputs.brandCarried || ''}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} container>
                  <Grid xs={12} className={classes.projectDetailInput}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Locations Served
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="locationsServed"
                      onChange={handleInputChange}
                      value={inputs.locationsServed || ''}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} container>
                  <Grid xs={12} className={classes.projectDetailInput}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Company Address
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="companyAddress"
                      onChange={handleInputChange}
                      value={inputs.companyAddress || ''}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  container
                  direction=""
                  className={classes.projectDetailsBox}
                >
                  {/*<Grid xs={12} md={12} className={classes.projectDetailInput}>*/}
                  {/*  <Button*/}
                  {/*    fullWidth*/}
                  {/*    variant="contained"*/}
                  {/*    className={classes.submitButton}*/}
                  {/*    onClick={handleSubmit}*/}
                  {/*  >*/}
                  {/*    <Typography variant="h6" className={classes.buttonLabel}>*/}
                  {/*      Save & Next*/}
                  {/*    </Typography>*/}
                  {/*  </Button>*/}
                  {/*</Grid>*/}
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={6} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="right"
              >
                <Grid xs={12} container className={classes.projectDetailsBox}>
                  <Grid xs={12} md={12} className={classes.projectDetailInput}>
                    <Typography variant="h4">
                      UPLOAD YOUR BUSINESS DOCUMENTS
                    </Typography>
                  </Grid>
                </Grid>

                <Grid xs={12} container className={classes.projectDetailInput}>
                  <Grid xs={12}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Copy of Sales Invoice or Official Receipt (10MB limit)
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="officialReceipt"
                      onChange={handleInputChange}
                      value={get(files, 'officialReceipt.name', '') || get(inputs, 'officialReceipt.name', '')}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid xs={12} style={{ paddingTop: 10 }}>
                    <Button component="label" style={{ backgroundColor: 'rgb(240, 243, 244)', color: '#67A9E0' }}>
                      <img
                        src={UPLOAD_ICON}
                        style={{ height: 30, objectFit: 'contain', paddingRight: 20 }}
                      />
                      <Typography>UPLOAD A FILE</Typography>
                      <input
                        type="file"
                        name="officialReceipt"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Grid>
                </Grid>

                <Grid xs={12} container className={classes.projectDetailInput}>
                  <Grid xs={12}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Bureau of Internal Revenue Certification (10MB limit)
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="birCertificate"
                      onChange={handleInputChange}
                      value={get(files, 'birCertificate.name', '') || get(inputs, 'birCertificate.name', '')}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid xs={12} style={{ paddingTop: 10 }}>
                    <Button component="label" style={{ backgroundColor: 'rgb(240, 243, 244)', color: '#67A9E0' }}>
                      <img
                        src={UPLOAD_ICON}
                        style={{ height: 30, objectFit: 'contain', paddingRight: 20 }}
                      />
                      <Typography>UPLOAD A FILE</Typography>
                      <input
                        type="file"
                        name="birCertificate"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid xs={12} container className={classes.projectDetailInput}>
                  <Grid xs={12}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Proof of Account Ownership (10MB limit)
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="proofOfAccountOwnership"
                      onChange={handleInputChange}
                      value={get(files, 'proofOfAccountOwnership.name', '') || get(inputs, 'proofOfAccountOwnership.name', '')}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid xs={12} style={{ paddingTop: 10 }}>
                    <Button component="label" style={{ backgroundColor: 'rgb(240, 243, 244)', color: '#67A9E0' }}>
                      <img
                        src={UPLOAD_ICON}
                        style={{ height: 30, objectFit: 'contain', paddingRight: 20 }}
                      />
                      <Typography>UPLOAD A FILE</Typography>
                      <input
                        type="file"
                        name="proofOfAccountOwnership"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid xs={12} container className={classes.projectDetailInput}>
                  <Grid xs={12}>
                    <InputLabel style={{ paddingBottom: 15, color: '#BEC1CF' }}>
                      Catalogs and Pricelists - Optional (10MB limit)
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="catalogsAndPricelists"
                      onChange={handleInputChange}
                      value={get(files, 'catalogsAndPricelists.name', '') || get(inputs, 'catalogsAndPricelists.name', '')}
                      fullWidth
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid xs={12} style={{ paddingTop: 10 }}>
                    <Button component="label" style={{ backgroundColor: 'rgb(240, 243, 244)', color: '#67A9E0' }}>
                      <img
                        src={UPLOAD_ICON}
                        style={{ height: 30, objectFit: 'contain', paddingRight: 20 }}
                      />
                      <Typography>UPLOAD A FILE</Typography>
                      <input
                        type="file"
                        name="catalogsAndPricelists"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} style={{ backgroundColor: '#67A9E0' }}>
            <Typography style={{
              textAlign: 'center', fontSize: '37', color: '#2F4E6B', fontFamily: 'Poppins-Bold'
            }}
            >
              LEARN MORE ABOUT VENDOR BAY PLATFORM FEES: LINK
            </Typography>
          </Grid>

          <Grid container xs={12} md={12} style={{ paddingTop: 50 }}>
            <Grid xs={12} md={6} style={{ backgroundColor: '#F0F3F4', padding: 50 }}>
              <Typography style={{fontSize: '24.67', color: '#2F4E6B', fontFamily: 'Poppins-Medium', textAlign: 'justify'
              }}
              >
                I have read and understood Vendor Bay’s platform fee
                agreement. Vendor Bay will charge me 2% platform fee
                for every successful transaction with a new buyer, billed
                every end of the month.
              </Typography>
              <Grid xs={12} style={{ paddingLeft: -60 }}>
                <Checkbox style={{ padding: 0, backgroundColor: 'white' }} />
              </Grid>
            </Grid>

            <Grid
              container
              md={6}
              direction="row-reverse"
              alignItems={'flex-end'}
            >
              <Grid item xs={5} style={{textAlign: 'right'}}>
                <Button onClick={handleSubmit} style={{backgroundColor: '#67A9E0', color: 'white', fontFamily: 'Poppins-Medium', fontSize: 28}}>
                  SUBMIT
                </Button>
              </Grid>

            </Grid>

          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

Form.propTypes = {
  className: PropTypes.string
};

export default Form;

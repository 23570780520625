import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import ChipInput from 'material-ui-chip-input';
import get from 'lodash/get';
import { useSubmitBidForm } from './submitBid.hooks';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  componentTitleBox: {
    marginBottom: 50
  },
  componentTitle: {
    fontSize: 16
  },
  createdAt: {
    color: '#959AA5',
    marginBottom: 10
  },
  projectTitle: {
    fontSize: 24
  },
  projectDetailsBox: {
    paddingTop: 50
  },
  projectDetailInput: {
    paddingBottom: 20
  },
  container: {
    padding: 50
  },
  label: {
    fontSize: 13
  },
  labelContainer: {
    paddingBottom: 10
  },
  documentRequirementContainer: {
    border: '1px solid #EDF2F9',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  addNewProductButton: {
    background: '#9ECAB5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  buttonLabel: {
    color: 'white'
  },
  buttonIcon: { color: 'white', marginRight: 10 },
  submitButton: {
    background: '#9FC6DD',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
    borderRadius: 4,
    height: 50.22
  },
  productImage: { width: 161, height: 161 }
}));

const Form = ({}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    handleInputChange,
    inputs,
    products,
    productInfo,
    proposalInfo
  } = useSubmitBidForm();

  console.log(proposalInfo);
  console.log(products);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Card className={classes.container}>
        <CardContent>
          <Box alignItems="flex-start" display="flex" flexDirection="column">
            <Box className={classes.componentTitleBox}>
              <Typography
                align="left"
                variant="h5"
                className={classes.componentTitle}
              >
                Submit Bid
              </Typography>
            </Box>
            {/*<Typography align="left" variant="h6" className={classes.createdAt}>*/}
            {/*  {`Created At: ${inputs.createdAt.toLocaleDateString()}`}*/}
            {/*</Typography>*/}
            {/*<TextField*/}
            {/*  id="projectName"*/}
            {/*  name="projectName"*/}
            {/*  onChange={handleInputChange}*/}
            {/*  value={inputs.projectName}*/}
            {/*  placeholder="Project Name"*/}
            {/*  InputProps={{ disableUnderline: true }}*/}
            {/*  inputProps={{ className: classes.projectTitle }}*/}
            {/*/>*/}
          </Box>
          <Divider />
          <Grid
            container
            direction="column"
            className={classes.projectDetailsBox}
          >
            {/*<Grid xs={12} md={6} className={classes.projectDetailInput}>*/}
            {/*  <NumberFormat*/}
            {/*    onValueChange={values => {*/}
            {/*      handleBudgetChange(values.floatValue);*/}
            {/*    }}*/}
            {/*    customInput={TextField}*/}
            {/*    prefix="₱"*/}
            {/*    thousandSeparator*/}
            {/*    fullWidth*/}
            {/*    name="budget"*/}
            {/*    variant="outlined"*/}
            {/*    label="Budget"*/}
            {/*    value={inputs.budget}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} md={6} className={classes.projectDetailInput}>*/}
            {/*  <KeyboardDatePicker*/}
            {/*    variant="outlined"*/}
            {/*    disableToolbar*/}
            {/*    inputVariant="outlined"*/}
            {/*    format="MM/dd/yyyy"*/}
            {/*    id="deliveryDate"*/}
            {/*    label="Delivery Date"*/}
            {/*    value={*/}
            {/*      inputs.deliveryDate ? new Date(inputs.deliveryDate) : null*/}
            {/*    }*/}
            {/*    onChange={date => handleDateChange(date, 'deliveryDate')}*/}
            {/*    KeyboardButtonProps={{*/}
            {/*      'aria-label': 'change date'*/}
            {/*    }}*/}
            {/*    fullWidth*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} md={6} className={classes.projectDetailInput}>*/}
            {/*  <KeyboardDatePicker*/}
            {/*    variant="outlined"*/}
            {/*    disableToolbar*/}
            {/*    inputVariant="outlined"*/}
            {/*    format="MM/dd/yyyy"*/}
            {/*    id="bidEndDate"*/}
            {/*    label="Bid End Date"*/}
            {/*    value={inputs.bidEndDate ? new Date(inputs.bidEndDate) : null}*/}
            {/*    onChange={date => handleDateChange(date, 'bidEndDate')}*/}
            {/*    KeyboardButtonProps={{*/}
            {/*      'aria-label': 'change date'*/}
            {/*    }}*/}
            {/*    fullWidth*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} md={6} className={classes.projectDetailInput}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    label="Delivery Place"*/}
            {/*    name="deliveryPlace"*/}
            {/*    onChange={handleInputChange}*/}
            {/*    value={inputs.deliveryPlace || ''}*/}
            {/*    fullWidth*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} md={6} className={classes.projectDetailInput}>*/}
            {/*  <ChipInput*/}
            {/*    variant="outlined"*/}
            {/*    fullWidth*/}
            {/*    value={inputs.tags}*/}
            {/*    label="Tags"*/}
            {/*    onAdd={handleAddTag}*/}
            {/*    onDelete={handleDeleteTag}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} md={6} className={classes.labelContainer}>*/}
            {/*  <Typography variant="subtitle1" className={classes.label}>*/}
            {/*    Business Documents Requirements*/}
            {/*  </Typography>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} md={6} className={classes.projectDetailInput}>*/}
            {/*  <Card className={classes.documentRequirementContainer}>*/}
            {/*    <CardContent>*/}
            {/*      <Grid*/}
            {/*        container*/}
            {/*        direction="row"*/}
            {/*        justify="center"*/}
            {/*        alignItems="center"*/}
            {/*      >*/}
            {/*        <Grid xs={12} md={6} justify="center" alignItems="center">*/}
            {/*          <FormControlLabel*/}
            {/*            control={*/}
            {/*              <Checkbox*/}
            {/*                name="sec"*/}
            {/*                checked={inputs.businessRequirements.sec || false}*/}
            {/*                onChange={handleBusinessReqChange}*/}
            {/*              />*/}
            {/*            }*/}
            {/*            label="SEC"*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*        <Grid xs={12} md={6}>*/}
            {/*          <FormControlLabel*/}
            {/*            control={*/}
            {/*              <Checkbox*/}
            {/*                name="or"*/}
            {/*                checked={inputs.businessRequirements.or || false}*/}
            {/*                onChange={handleBusinessReqChange}*/}
            {/*              />*/}
            {/*            }*/}
            {/*            label="OR"*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*        <Grid xs={12} md={6}>*/}
            {/*          <FormControlLabel*/}
            {/*            control={*/}
            {/*              <Checkbox*/}
            {/*                name="dti"*/}
            {/*                checked={inputs.businessRequirements.dti || false}*/}
            {/*                onChange={handleBusinessReqChange}*/}
            {/*              />*/}
            {/*            }*/}
            {/*            label="DTI"*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*        <Grid xs={12} md={6}>*/}
            {/*          <FormControlLabel*/}
            {/*            control={*/}
            {/*              <Checkbox*/}
            {/*                name="businessPermit"*/}
            {/*                checked={*/}
            {/*                  inputs.businessRequirements.businessPermit ||*/}
            {/*                  false*/}
            {/*                }*/}
            {/*                onChange={handleBusinessReqChange}*/}
            {/*              />*/}
            {/*            }*/}
            {/*            label="Business Permit"*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*        <Grid xs={12} md={6}>*/}
            {/*          <FormControlLabel*/}
            {/*            control={*/}
            {/*              <Checkbox*/}
            {/*                name="bir2303"*/}
            {/*                checked={*/}
            {/*                  inputs.businessRequirements.bir2303 || false*/}
            {/*                }*/}
            {/*                onChange={handleBusinessReqChange}*/}
            {/*              />*/}
            {/*            }*/}
            {/*            label="BIR 2303"*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*        <Grid xs={12} md={6}>*/}
            {/*          <FormControlLabel*/}
            {/*            control={*/}
            {/*              <Checkbox*/}
            {/*                name="dr"*/}
            {/*                checked={inputs.businessRequirements.dr || false}*/}
            {/*                onChange={handleBusinessReqChange}*/}
            {/*              />*/}
            {/*            }*/}
            {/*            label="D.R."*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*      </Grid>*/}
            {/*    </CardContent>*/}
            {/*  </Card>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} md={6} className={classes.labelContainer}>*/}
            {/*  <Typography variant="h4">Products</Typography>*/}
            {/*</Grid>*/}

            {Object.entries(products).map(([key, productInfo]) => (
              <>
                <Grid xs={12} md={6} className={classes.labelContainer} key={key}>
                  <Typography variant="subtitle1" className={classes.label}>
                    Product Requirements
                  </Typography>
                </Grid>
                <Grid xs={12} md={10} className={classes.projectDetailInput}>
                  <Card className={classes.documentRequirementContainer}>
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid
                          xs={12}
                          md={3}
                          justify="center"
                          alignItems="center"
                        >
                          <Avatar
                            variant="square"
                            src={productInfo.productImageURI}
                            className={classes.productImage}
                          >
                            Product Image
                          </Avatar>
                        </Grid>
                        <Grid
                          xs={12}
                          md={9}
                          item
                          direction="column"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid>
                            <Grid
                              xs={12}
                              className={classes.projectDetailInput}
                            >
                              <FormControl variant="outlined" fullWidth>
                                <InputLabel>Product Type</InputLabel>
                                <Select
                                  disabled
                                  name="productType"
                                  value={productInfo.productType || ''}
                                >
                                  <MenuItem value="Product">Product</MenuItem>
                                  <MenuItem value="Service">Service</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid
                              xs={12}
                              className={classes.projectDetailInput}
                            >
                              <TextField
                                disabled
                                variant="outlined"
                                label="Product Name"
                                name="productName"
                                value={productInfo.productName || ''}
                                fullWidth
                              />
                            </Grid>
                            <Grid container xs={12} direction={'row'}>
                              <Grid
                                xs={12}
                                md={6}
                                className={classes.projectDetailInput}
                              >
                                <TextField
                                  disabled
                                  variant="outlined"
                                  label="Quantity"
                                  type="number"
                                  fullWidth
                                  name="quantity"
                                  value={productInfo.quantity || ''}
                                />
                              </Grid>
                              <Grid
                                xs={12}
                                md={6}
                                className={classes.projectDetailInput}
                              >
                                <TextField
                                  variant="outlined"
                                  label="Enter Quantity"
                                  type="number"
                                  fullWidth
                                  name="quantity"
                                  value={
                                    get(inputs, `${key}.quantity`, null) || get(proposalInfo, `${key}.quantity`, null)
                                  }
                                  onChange={(e) => handleInputChange(e, key)}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              xs={12}
                              className={classes.projectDetailInput}
                            >
                              <TextField
                                disabled
                                variant="outlined"
                                label="Specifications"
                                fullWidth
                                value={productInfo.specifications || ''}
                              />
                            </Grid>
                            <Grid xs={12} container direction={'row'}>
                              <Grid
                                xs={12}
                                md={6}
                                className={classes.projectDetailInput}
                              >
                                <NumberFormat
                                  disabled
                                  customInput={TextField}
                                  prefix="₱"
                                  thousandSeparator
                                  fullWidth
                                  name="price"
                                  variant="outlined"
                                  label="Price"
                                  value={productInfo.price || ''}
                                />
                              </Grid>
                              <Grid
                                xs={12}
                                md={6}
                                className={classes.projectDetailInput}
                              >
                                <NumberFormat
                                  customInput={TextField}
                                  prefix="₱"
                                  thousandSeparator
                                  fullWidth
                                  name="price"
                                  variant="outlined"
                                  label="Enter Price"
                                  value={get(inputs, `${key}.price`, null) || get(proposalInfo, `${key}.price`, null)}
                                  onChange={(e) => handleInputChange(e, key)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ))}

            <Grid
              xs={12}
              md={12}
              container
              justify="center"
              alignItems="center"
              className={classes.projectDetailInput}
            >
              <Grid xs={12} md={7} style={{ paddingTop: 40 }}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.submitButton}
                  onClick={(e) => handleSubmit(e)}
                >
                  <Typography variant="h6" className={classes.buttonLabel}>
                    Submit Proposal
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </MuiPickersUtilsProvider>
  );
};

Form.propTypes = {
  className: PropTypes.string
};

export default Form;

import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import BidCard from './BidCard';
import { useBids } from './bidslist.hooks';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  listContainer: {
    paddingBottom: 20
  }
}));

const BidsList = () => {
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const { bids } = useBids();

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={'xl'}>
        <Grid container justify="center" alignItems="stretch">
          <Grid item xs={12} md={10} className={classes.listContainer}>
            <BidCard data={bids} type={'Bids'}/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default BidsList;

import React, { useState } from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

function generatePassword() {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const useCustomerList = callback => {
  const firestore = useFirestore();
  const profile = useSelector(state => state.firebase.profile);

  useFirestoreConnect([
    {
      collection: 'Users',
      // where: [
      //   ['default', '==', true],
      //   ['active', '==', true]
      // ],
      // limit: 1,
      storeAs: 'customerList'
    }
  ]);

  const updateCustomerAttribute = async data => {
    console.log(data);
    await firestore
      .collection('Users')
      .doc(data.uid)
      .set(data, { merge: true });
  };

  const customerList = useSelector(state => {
    console.log(state);
    return state.firestore.data.customerList;
  });

  // Temporary
  const inviteEmployee = async data => {
    console.log(data);
    await firestore
      .collection('InvitedUsers')
      .doc()
      .set({...data, password: generatePassword()}, { merge: true });
  };

  return {
    inviteEmployee,
    updateCustomerAttribute,
    customerList
  };
};

export { useCustomerList };

import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import AdminBidsListView from 'src/views/admin/BidsListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import Homepage from 'src/views/landing/Homepage';
import AboutUspage from 'src/views/landing/AboutUspage';
import KnowledgedHubpage from 'src/views/landing/KnowledgeHubpage';
import NotFoundView from 'src/views/errors/NotFoundView';
import AccountNeedsVerification from 'src/views/errors/AccountNeedsVerification';
import ProductListView from 'src/views/product/ProductListView';
import BidsListView from 'src/views/bids/BidsListView';
import ProjectsListView from 'src/views/projects/ProjectsListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import PrivateRoute from 'src/enhancers/PrivateRoute';
import CreateBid from 'src/views/bids/CreateBidView';
import Home from 'src/views/home/HomeView';
import BuyCoins from './views/payment/BuyCoinsView';
import SubmitBid from './views/bids/SubmitBidView';
import CompleteProfile from './views/profile/CompleteProfileView';
import CustomerDetailsView from './views/admin/CustomerDetailsView';
import TeamsListView from './views/teams/TeamsListView';

const routes = [
  // {
  //   path: 'app',
  //   element: <DashboardLayout />,
  //   children: [
  //     // { path: 'account', element: <AccountView /> },
  //     { path: 'buy-coins', element: <PrivateRoute component={BuyCoins} /> },
  //     { path: 'submit-bid/:bidID', element: <PrivateRoute component={SubmitBid} /> },
  //     { path: 'create-bid', element: <PrivateRoute component={CreateBid} /> },
  //     { path: 'home', element: <PrivateRoute component={Home} /> },
  //     { path: 'products', element: <PrivateRoute component={ProjectsListView} /> },
  //     { path: 'complete-profile', element: <CompleteProfile /> },
  //     { path: 'employees', element: <TeamsListView /> },
  //
  //     // { path: 'dashboard', element: <PrivateRoute component={DashboardView}/> },
  //     // { path: 'products', element: <ProjectsListView /> },
  //     // { path: 'settings', element: <SettingsView /> },
  //     { path: '*', element: <Navigate to="/app/home" /> }
  //   ]
  // },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Homepage /> },
      { path: '/about-us', element: <AboutUspage /> },
      { path: '/knowledge-hub', element: <KnowledgedHubpage /> },
      { path: '', element: <LoginView /> },
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: 'verification-pending', element:<PrivateRoute component={AccountNeedsVerification} /> },
      { path: 'complete-profile', element: <PrivateRoute component={CompleteProfile} />  },
      { path: 'account', element: <PrivateRoute component={AccountView} /> },
      // { path: 'create-bid', element: <PrivateRoute component={CreateBid} /> },


      { path: 'dashboard', element: <PrivateRoute component={ProjectsListView} /> },
      { path: 'my/project/:projectID', element: <PrivateRoute component={BidsListView} /> },

      { path: 'rfq', element: <PrivateRoute component={BidsListView} /> },
      { path: 'bids', element: <PrivateRoute component={BidsListView} /> },

      // { path: 'products', element: <ProductListView /> },

      { path: 'admin/bids', element: <PrivateRoute component={AdminBidsListView} /> },
      { path: 'submit-bid/:bidID/:projectID', element: <PrivateRoute component={SubmitBid} /> },

      { path: 'admin/customers', element: <PrivateRoute component={CustomerListView} /> },
      { path: 'admin/customer/:uid', element: <PrivateRoute component={CustomerDetailsView} /> },

      { path: 'teams', element: <PrivateRoute component={TeamsListView} /> },


      // { path: 'projects', element: <PrivateRoute component={ProjectsList} /> },
      // { path: '/', element: <Navigate to="/app/dashboard" /> },
      // { path: '*', element: <Navigate to="/login" /> }
    ]
  }
];

export default routes;

import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import SearchBar from 'material-ui-search-bar';
import Hero_Banner from 'src/assets/home/Hero_Banner.png';
import Advertisement_Banner_1 from 'src/assets/home/Advertisement Banner.png';
import Advertisement_Banner_2 from 'src/assets/home/Advertisement_Banner.png';
import BidsList from './BidsList';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  componentTitleBox: {
    marginBottom: 50
  },
  componentTitle: {
    fontSize: 16
  },
  createdAt: {
    color: '#959AA5',
    marginBottom: 10
  },
  projectTitle: {
    fontSize: 24
  },
  projectDetailsBox: {
    paddingTop: 50
  },
  projectDetailInput: {
    paddingBottom: 20
  },
  container: {},
  label: {
    fontSize: 13
  },
  labelContainer: {
    paddingBottom: 10
  },
  documentRequirementContainer: {
    border: '1px solid #EDF2F9',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  addNewProductButton: {
    background: '#9ECAB5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  buttonLabel: {
    color: 'white'
  },
  buttonIcon: { color: 'white', marginRight: 10 },
  submitButton: {
    background: '#9FC6DD',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
    borderRadius: 4,
    height: 50.22
  },
  bidContainer: {
    padding: 20
  },
  image: {
    // // backgroundImage: `url(require("src/assets/login/Login_Banner.png"))`,
    // background: `url(${Hero_Banner})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'contain',
    // backgroundPosition: 'center',
    // width: '100%',
    // height: 700,

    position: 'relative'
  },
  priceText: {
    color: '#009E7F',
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 400
  },
  qtyText: {
    fontFamily: 'Lato',
    color: '#009E7F',
    fontSize: 16,
    fontWeight: 400
  },
  productNameText: {
    fontFamily: 'Lato',
    fontSize: 20,
    color: '#0D1136',
    fontWeight: 400
  },
  closingDateText: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 400
  },
  bidButton: {
    backgroundColor: 'white',
    width: '30%',
    textAlign: 'center',
    border: '2px solid #F7F7F7',
    boxSizing: 'border-box',
    borderRadius: 18
  },
  bidButtonText: {
    color: '#009E7F'
  },
  bidButtonBox: {
    textAlign: 'center',
    fontSize: 13,
    paddingTop: 10
  },
  addButtonIcon: {
    color: '#009E7F',
    paddingLeft: 5
  },
  roundNumbering: {
    height: '4rem',
    width: '4rem',
    textAlign: 'center',
    justifyContent: 'center',
    border: 'unset'
  },
  instructionsContainer: {
    border: '1px solid #E6E6E6',
    borderRadius: 6,
    paddingTop: 25,
    paddingBottom: 25
  },
  numberingText: {
    color: '#575757',
    fontSize: 27
  },
  instructionHeading: {
    fontWeight: 'normal',
    fontSize: 23,
    color: '#212121',
    lineHeight: '29px',
    paddingBottom: 10
  },
  instructionDescription: {
    fontWeight: 'normal',
    fontSize: 18,
    fontFamily: 'Open Sans',
    color: '##575757',
    lineHeight: '24px'
  },
  stepContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  instructionsRow: {
    marginTop: 30,
    marginBottom: 30
  },
  advertisementBanner: {
    padding: 20
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const HomeCard = ({}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Card className={classes.container}>
        <CardContent>
          {/* Hero Banner */}
          <Grid item xs={12} className={classes.image}>
            <Box>
              <img
                src={Hero_Banner}
                style={{ width: '100%', objectFit: 'contain' }}
              />
            </Box>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: 10
              }}
            >
              <SearchBar
                onChange={() => console.log('onChange')}
                onRequestSearch={() => console.log('onRequestSearch')}
                style={{
                  margin: '0 auto',
                  width: '50vw',
                  height: '4vh'
                }}
              />
            </div>
          </Grid>

          {/* Instructions */}
          <Grid item xs={12} className={classes.instructionsRow}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={10}
                className={classes.instructionsContainer}
              >
                <Grid container>
                  <Grid xs={12} md={6} className={classes.stepContainer}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="center">
                          <Box
                            className={classes.roundNumbering}
                            borderRadius="100%"
                            border={1}
                            style={{ backgroundColor: '#F5EAC6' }}
                          >
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              justify="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item xs={6}>
                                <Typography
                                  variant="h4"
                                  className={classes.numberingText}
                                >
                                  1
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="h3"
                          className={classes.instructionHeading}
                        >
                          Find a Bid
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.instructionDescription}
                        >
                          Find bids that suit you using the above search bar. We
                          have also provided you with a carefully curated list
                          of bids down below
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={12} md={6} className={classes.stepContainer}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="center">
                          <Box
                            className={classes.roundNumbering}
                            borderRadius="100%"
                            border={1}
                            style={{ backgroundColor: '#DCE1E1' }}
                          >
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              justify="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item xs={6}>
                                <Typography
                                  variant="h4"
                                  className={classes.numberingText}
                                >
                                  3
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="h3"
                          className={classes.instructionHeading}
                        >
                          Awarding
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.instructionDescription}
                        >
                          From this point, you’ll get a notification that a
                          requestor has chosen you. Now it’s time to communicate
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={12} md={6} className={classes.stepContainer}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="center">
                          <Box
                            className={classes.roundNumbering}
                            borderRadius="100%"
                            border={1}
                            style={{ backgroundColor: '#DCE1E1' }}
                          >
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              justify="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item xs={6}>
                                <Typography
                                  variant="h4"
                                  className={classes.numberingText}
                                >
                                  2
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="h3"
                          className={classes.instructionHeading}
                        >
                          Submit Your Offer
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.instructionDescription}
                        >
                          Once you’ve found a bid, all you need are bid coins to
                          submit an offer
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={12} md={6} className={classes.stepContainer}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="center">
                          <Box
                            className={classes.roundNumbering}
                            borderRadius="100%"
                            border={1}
                            style={{ backgroundColor: '#DCE1E1' }}
                          >
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              justify="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item xs={6}>
                                <Typography
                                  variant="h4"
                                  className={classes.numberingText}
                                >
                                  4
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          variant="h3"
                          className={classes.instructionHeading}
                        >
                          Fufill The Requirements
                        </Typography>
                        <Typography
                          variant="h4"
                          className={classes.instructionDescription}
                        >
                          Provide the product/service then you’re all good to go
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ADS */}
          <Grid item xs={12} spacing={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"

            >
              <Grid item xs={12} md={10}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} md={4} className={classes.advertisementBanner}>
                    <img
                      src={Advertisement_Banner_1}
                      style={{ width: '100%', objectFit: 'contain' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.advertisementBanner}>
                    <img
                      src={Advertisement_Banner_2}
                      style={{ width: '100%', objectFit: 'contain' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.advertisementBanner}>
                    <img
                      src={Advertisement_Banner_1}
                      style={{ width: '100%', objectFit: 'contain' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} spacing={3}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={10}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <BidsList classes={classes} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </MuiPickersUtilsProvider>
  );
};

HomeCard.propTypes = {
  className: PropTypes.string
};

export default HomeCard;

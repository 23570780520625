import React from 'react';
import {
  Card, CardContent,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { PayPalButton } from 'react-paypal-button-v2';
import PackageList from './PackageList';
import { useBuyCoins } from './buycoins.hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BuyCoins = () => {
  const { selectedPackage, handleChosenPackage } = useBuyCoins();
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems=""
        >
          <Grid
            item
            xs={12}
            md={11}
          >
            <Card className={classes.container}>
              <CardContent>
                <PackageList selectedPackage={selectedPackage} handleChosenPackage={handleChosenPackage}/>
                <PayPalButton
                  amount={99}
                  currency="PHP"
                  onSuccess={(details, data) => {
                    console.log(details);
                    console.log(data);
                  }}
                  options={{
                    currency: 'PHP',
                    clientId: 'AQlLLAQm4FGrnstPeeiCio5DboqaBjJJoRjWpzH9jWrlVixNjC4hGRg8MNOfJy5F1afvfP4apY73wo9v'
                  }}
                />
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

export default BuyCoins;

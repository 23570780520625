import React, { useCallback, useState } from 'react';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';

const useRegister = callback => {
  const { navigate } = callback;
  const firebase = useFirebase();
  const firestore = useFirestore();

  const profile = useSelector(state => state.firebase.profile);

  const [inputs, setInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();

      const fieldsComplete = Object.values(inputs).every(input => !!input);

      if (!fieldsComplete) {
        alert('Make sure all fields are filled out');
        return;
      }

      if (inputs.password !== inputs.confirmPassword) {
        alert('Passwords must match');
        return;
      }

      firebase
        .auth()
        .createUserWithEmailAndPassword(inputs.email, inputs.password)
        .then(({ user }) => {
          console.log(user);
          console.log(user.uid);

          const userDocumentRef = firestore.collection('Users').doc(user.uid);

          userDocumentRef
            .set({
              firstName: inputs.firstName,
              lastName: inputs.lastName,
              email: inputs.email,
              uid: user.uid,
              companyID: user.uid,
            })
            .then(() => {
              navigate('/account', { replace: true });
            })
            .catch(e => navigate('/login'));
        })
        .catch(e => {
          alert(e);
        });
    }
  };

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs
  };
};

export { useRegister };

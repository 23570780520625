import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MaterialTable from 'material-table';
import {
  Box,
  Card,
  Checkbox,
  TablePagination,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { useCustomerList } from './customerlist.hooks';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const columns = [
  { title: 'Bid End Data', field: 'bidEndData' },
  { title: 'Budget', field: 'budget' },
  {
    title: 'Business Requirements',
    field: 'businessRequirements',
    render: ({businessRequirements}) => Object.keys(businessRequirements).filter((k) => {return businessRequirements[k]}).join(', ')

  },
  // { title: 'Created At', field: 'createdAt' },
  { title: 'Delivery Date', field: 'deliveryDate' },
  { title: 'Delivery Place', field: 'deliveryPlace' },
  { title: 'Project Owner', field: 'projectOwner' },
  { title: 'Tags', field: 'tags' }
];

const BidsList = ({ className, customers, ...rest }) => {
  const classes = useStyles();

  const { bidsList, updateBidAttributes } = useCustomerList();

  console.log(bidsList);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const a = bidsList
    ? Object.entries(bidsList).map(([key, value]) => value)
    : [];
  console.log(a);

  const b = [...a];
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MaterialTable
            title={'Admin Bids List'}
            columns={[
              ...columns,
              {
                title: 'Approved',
                render: rowData => (

                  <Select
                    value={rowData.approved}
                    label="Approved"
                    onChange={event =>
                      updateBidAttributes({
                        approved: event.target.value,
                        bidID: rowData.bidID,
                        projectID: rowData.projectID
                      })}
                  >
                    <MenuItem value={'approved'}>Approved</MenuItem>
                    <MenuItem value={'needsCorrection'}>Needs Correction</MenuItem>
                  </Select>
                )
              }
            ]}
            data={JSON.parse(JSON.stringify(b))}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

BidsList.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default BidsList;

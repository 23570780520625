import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  componentTitleBox: {
    marginBottom: 50
  },
  componentTitle: {
    fontSize: 16
  },
  createdAt: {
    color: '#959AA5',
    marginBottom: 10
  },
  projectTitle: {
    fontSize: 24
  },
  projectDetailsBox: {
    paddingTop: 50
  },
  projectDetailInput: {
    paddingBottom: 20
  },
  container: {},
  label: {
    fontSize: 13
  },
  labelContainer: {
    paddingBottom: 10
  },
  documentRequirementContainer: {
    border: '1px solid #EDF2F9',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  addNewProductButton: {
    background: '#9ECAB5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  buttonLabel: {
    color: 'white'
  },
  buttonIcon: { color: 'white', marginRight: 10 },
  submitButton: {
    background: '#9FC6DD',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
    borderRadius: 4,
    height: 50.22
  },
  bidContainer: {
    padding: 20
  },
  image: {
    // // backgroundImage: `url(require("src/assets/login/Login_Banner.png"))`,
    // background: `url(${Hero_Banner})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'contain',
    // backgroundPosition: 'center',
    // width: '100%',
    // height: 700,

    position: 'relative'
  },
  priceText: {
    color: '#009E7F',
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 400
  },
  qtyText: {
    fontFamily: 'Lato',
    color: '#009E7F',
    fontSize: 16,
    fontWeight: 400
  },
  productNameText: {
    fontFamily: 'Lato',
    fontSize: 20,
    color: '#0D1136',
    fontWeight: 400
  },
  closingDateText: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 400
  },
  bidButton: {
    backgroundColor: 'white',
    width: '30%',
    textAlign: 'center',
    border: '2px solid #F7F7F7',
    boxSizing: 'border-box',
    borderRadius: 18
  },
  bidButtonText: {
    color: '#009E7F'
  },
  bidButtonBox: {
    textAlign: 'center',
    fontSize: 13,
    paddingTop: 10
  },
  addButtonIcon: {
    color: '#009E7F',
    paddingLeft: 5
  },
  roundNumbering: {
    height: '4rem',
    width: '4rem',
    textAlign: 'center',
    justifyContent: 'center',
    border: 'unset'
  },
  instructionsContainer: {
    border: '1px solid #E6E6E6',
    borderRadius: 6,
    paddingTop: 25,
    paddingBottom: 25
  },
  numberingText: {
    color: '#575757',
    fontSize: 27
  },
  instructionHeading: {
    fontWeight: 'normal',
    fontSize: 23,
    color: '#212121',
    lineHeight: '29px',
    paddingBottom: 10
  },
  instructionDescription: {
    fontWeight: 'normal',
    fontSize: 18,
    fontFamily: 'Open Sans',
    color: '##575757',
    lineHeight: '24px'
  },
  stepContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  instructionsRow: {
    marginTop: 30,
    marginBottom: 30
  },
  advertisementBanner: {
    padding: 20
  },
  ovalNumbering: {
    height: '57px',
    width: '72px',
    textAlign: 'center',
    justifyContent: 'center',
    border: 'unset'
  },
  numberingText: {
    color: 'black'
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const PackageList = ({selectedPackage, handleChosenPackage}) => {
  const classes = useStyles();

  return (
          <Grid item xs={12} spacing={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"

            >
              <Grid item xs={12} md={10}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} md={2} className={classes.advertisementBanner}>
                    <PackageCard backgroundColor={'#F9E3AF'} classes={classes}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
  );
};

const PackageCard = (props) => {



  return (
    <Card style={{backgroundColor: props.backgroundColor}}>
      <CardContent>
        <Box
          className={props.classes.ovalNumbering}
          borderRadius="100%"
          border={1}
          style={{ backgroundColor: 'white' }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ height: '100%' }}
          >
            <Grid item xs={6}>
              <Typography
                variant="h4"
                className={props.classes.numberingText}
              >
                {props.price}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  )
}

PackageList.propTypes = {
  className: PropTypes.string
};

export default PackageList;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Avatar, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 40
  },
  componentTitleBox: {
    marginBottom: 50
  },
  addCircleIcon: {
    marginRight: 10,
    color: 'rgb(106, 106, 106)'
  },
  productContainer: {},
  productListContainer: {
    paddingTop: 30
  },
  dialog:{
  }
}));

const ProductCard = ({ data, type }) => {
  const classes = useStyles();

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  return (
    <>
      <Dialog
        className={classes.dialog}
        // fullScreen={true}
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
      >
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent>
          <Grid xs={12} md={10} style={{width: 500}} className={classes.projectDetailInput}>
            <Card className={classes.documentRequirementContainer}>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    xs={12}
                    md={3}
                    justify="center"
                    alignItems="center"
                  >
                    <Avatar
                      variant="square"
                      // src={product.productImageURI}
                      className={classes.productImage}
                    >
                      Product Image
                    </Avatar>
                    <Button
                      component="label"
                    >
                      Add Product Image
                      <input
                        // onChange={(event) => uploadImage(event, productIndex)}
                        type="file"
                        style={{ display: 'none' }}
                      />
                    </Button>
                  </Grid>
                  <Grid
                    xs={12}
                    md={9}
                    item
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid>
                      <Grid
                        xs={12}
                        className={classes.projectDetailInput}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel>Product Type</InputLabel>
                          <Select
                            name="productType"
                            // value={
                            //   products[productIndex].productType || ''
                            // }
                            // onChange={(event) => handleProductRequirementsChange(
                            //   event,
                            //   productIndex
                            // )}
                          >
                            <MenuItem value="Product">Product</MenuItem>
                            <MenuItem value="Service">Service</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        xs={12}
                        className={classes.projectDetailInput}
                      >
                        <TextField
                          variant="outlined"
                          label="Product Name"
                          name="productName"
                          // value={
                          //   products[productIndex].productName || ''
                          // }
                          // onChange={(event) => handleProductRequirementsChange(
                          //   event,
                          //   productIndex
                          // )}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        className={classes.projectDetailInput}
                      >
                        <TextField
                          variant="outlined"
                          label="Quantity"
                          type="number"
                          fullWidth
                          name="quantity"
                          // value={products[productIndex].quantity || ''}
                          // onChange={(event) => handleProductRequirementsChange(
                          //   event,
                          //   productIndex
                          // )}
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        className={classes.projectDetailInput}
                      >
                        <TextField
                          variant="outlined"
                          label="Specifications"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        className={classes.projectDetailInput}
                      >
                        <NumberFormat
                          // onValueChange={(values) => {
                          //   handlePriceChange(
                          //     values.floatValue,
                          //     productIndex
                          //   );
                          // }}
                          customInput={TextField}
                          prefix="₱"
                          thousandSeparator
                          fullWidth
                          name="price"
                          variant="outlined"
                          label="Price"
                          // value={products[productIndex].price || ''}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            // onClick={handleClose}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Card className={classes.container}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3} md={2}>
              <Typography
                align="left"
                variant="h5"
                className={classes.componentTitle}
              >
                {`${type} List`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button fullWidth onClick={() => setAddDialogOpen(true)}>
                <AddCircleIcon className={classes.addCircleIcon} />
                <Typography variant="h6" className={classes.componentTitle}>
                  {`Add ${type}`}
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.productListContainer}
          >
            {!!data
            && Object.entries(data)?.map(([productID, product]) => {
              return (
                <Grid
                  xs={12}
                  md={4}
                  lg={3}
                  className={classes.productContainer}
                  key={productID}
                >
                  <Box>
                    <img
                      src={product.productImageURI}
                      style={{ height: 247, width: '100%', objectFit: 'cover' }}
                    />
                  </Box>
                  <Typography variant="h6" className={classes.productNameText}>
                    {product.productName}
                  </Typography>
                  <Typography variant="h6" className={classes.priceText}>
                    <NumberFormat
                      value={product.price}
                      displayType="text"
                      thousandSeparator
                      prefix="₱"
                    />
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;

import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Page from 'src/components/Page';
import { useFirebase } from 'react-redux-firebase';
import Background1 from '../../assets/login/Login_Banner.png';

const useStyles = makeStyles(theme => ({
  root: {
  },
  image: {
    // backgroundImage: `url(require("src/assets/login/Login_Banner.png"))`,
    background: `url(${Background1})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(10, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#9FC6DD',
    color: 'white'
  },
  checkBox: {
    margin: theme.spacing(3, 0, 2)
  },
  textField: {
    background: 'white'
    // margin: theme.spacing(2, 0, 1),
  },
  divider: {
    width: '100%',
    backgroundColor: '#D8D8D8'
  },
  createAccountText: {
    color: '#9FC6DD'
  },
  button: {
    margin: theme.spacing(3, 0, 2)
  },
  welcome: {
    fontSize: 93.6,
    fontFamily: 'Poppins-Bold',
    textAlign: 'center',
    color: '#2f4e6b',
    paddingTop: 100
  },
  welcomeSubtitle: {
    fontSize: 40.11,
    fontFamily: 'Poppins-Medium',
    textAlign: 'center',
    color: '#2f4e6b',
    paddingTop: 10
  }
}));

const LoginView = () => {
  const firebase = useFirebase();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogin = useCallback(async () => {
    await firebase
      .login({
        email: 'juddguerrero@gmail.com',
        password: '99Fbrxs11'
      })
      .catch(e => console.log(e));

    navigate('/app/dashboard', { replace: true });
  }, [firebase]);

  return (
    <Page className={classes.root} title="Login">
      <Grid xs={12} container component="main" className={classes.root}>
        <Grid container justify="center" xs={12}>
          <Grid xs={12}>
            <Typography className={classes.welcome}>Welcome</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography className={classes.welcomeSubtitle}>
              Enter your details and start journey with us.
            </Typography>
          </Grid>

          <TextField
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            InputProps={{
              className: classes.input
            }}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="#9FC6DD"
            className={classes.submit}
            onClick={async () => await handleLogin()}
          >
            Sign In
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};

export default LoginView;

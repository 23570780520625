import React, { useState, useEffect } from 'react';
import {
  useFirestore,
  useFirebase,
  useFirestoreConnect
} from 'react-redux-firebase';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useSubmitBidForm = callback => {
  const firebase = useFirebase();
  const firestore = useFirestore();

  const { bidID, projectID } = useParams();

  const [inputs, setInputs] = useState({
    createdAt: new Date()
  });

  const auth = useSelector(state => state.firebase.auth);

  useFirestoreConnect(() => [`Users/${auth.uid}`]);

  const user = useSelector(
    ({ firestore: { data } }) => data.Users && data.Users[auth.uid]
  );

  useFirestoreConnect([
    {
      collection: 'Bids',
      doc: bidID,
      // where: [
      //   ['default', '==', true],
      //   ['active', '==', true]
      // ],
      // limit: 1,
      storeAs: `bid-${bidID}`
    }
  ]);

  useFirestoreConnect([
    {
      collection: `Bids/${bidID}/Products`,
      // where: [
      //   ['default', '==', true],
      //   ['active', '==', true]
      // ],
      // limit: 1,
      storeAs: 'products'
    }
  ]);

  useFirestoreConnect([
    {
      collection: `Bids/${bidID}/Proposals`,
      doc: user?.companyID,
      // where: [
      //   ['default', '==', true],
      //   ['active', '==', true]
      // ],
      // limit: 1,
      storeAs: `proposal-${bidID}`
    }
  ]);

  const productInfo = useSelector(
    state => state.firestore.data[`bid-${bidID}`]
  );

  const proposalInfo = useSelector(
    state => state.firestore.data[`proposal-${bidID}`]
  );

  const products = useSelector(state => state.firestore.data['products']);

  useEffect(() => {
    if (products) {
      const productKeys = Object.keys(products);
      const inputTemporary = {};
      productKeys.forEach(
        (productKey) => {
          (inputTemporary[productKey] = { createdAt: new Date().getTime() });
        }
      );
      setInputs(inputTemporary);
    }
  }, [products]);
  console.log(productInfo);

  console.log(inputs);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();

      await firestore
        .collection(`Bids/${bidID}/Proposals`)
        .doc(user.companyID)
        .set(
          {
            ...inputs
          },
          { merge: true }
        )
        .catch(e => alert(e));

      await firestore
        .collection(`Projects/${projectID}/Bids/${bidID}/Proposals`)
        .doc(user.companyID)
        .set(
          {
            ...inputs
          },
          { merge: true }
        )
        .catch(e => alert(e));

      alert('Bid Submitted Successfully');
    }
  };

  const handleInputChange = (event, key) => {
    event.persist();
    const copyOfKeyValues = inputs[key];
    copyOfKeyValues[event.target.name] = event.target.value;
    setInputs(inputs => ({
      ...inputs,
      [key]: {...copyOfKeyValues}
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
    productInfo: productInfo || {},
    proposalInfo: proposalInfo || {},
    products: products || []
  };
};

export { useSubmitBidForm };

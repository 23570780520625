import React, { useState } from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const useCustomerList = callback => {
  const firestore = useFirestore();
  const profile = useSelector(state => state.firebase.profile);

  useFirestoreConnect([
    {
      collection: 'Bids',
      // where: [
      //   ['default', '==', true],
      //   ['active', '==', true]
      // ],
      // limit: 1,
      storeAs: 'bids'
    }
  ]);

  const updateBidAttributes = async data => {
    console.log(data);
    await firestore
      .collection('Bids')
      .doc(data.bidID)
      .set(data, { merge: true });
    await firestore
      .collection(`Projects`)
      .doc(data.projectID)
      .collection('Bids')
      .doc(data.bidID)
      .set(data, { merge: true });
  };

  const bidsList = useSelector(state => {
    console.log(state);
    return state.firestore.data.bids;
  });

  return {
    updateBidAttributes,
    bidsList
  };
};

export { useCustomerList };

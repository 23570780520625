import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MaterialTable from 'material-table';
import {
  Box,
  Card,
  Checkbox,
  TablePagination,
  makeStyles,
  Button
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { useCustomerList } from './customerlist.hooks';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const CustomerList = ({ className, customers, ...rest }) => {
  const classes = useStyles();

  const {
    customerList,
    updateCustomerAttribute,
    inviteEmployee
  } = useCustomerList();
  const navigate = useNavigate();

  console.log(customerList);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const a = customerList
    ? Object.entries(customerList).map(([key, value]) => value)
    : [];
  console.log(a);

  const [newlyAddedCustomers, setNewlyAddedCustomers] = useState([]);

  const b = [...a, ...newlyAddedCustomers];

  console.log(b);
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MaterialTable
            columns={[
              { title: 'First Name', field: 'firstName' },
              { title: 'Middle Initial', field: 'middleInitial' },
              { title: 'Last Name', field: 'lastName' },
              { title: 'Email', field: 'email' },
              { title: 'Contact Number', field: 'contactNumber' },
              { title: 'Company Name', field: 'companyName' },
              { title: 'Categories', field: 'categories' },
              { title: 'Company Address', field: 'companyAddress' },
              { title: 'Designation', field: 'designation' },
              { title: 'Line of Business', field: 'lineOfBusiness' },
              { title: 'Locations Served', field: 'locationsServed' },
              {
                title: 'Tax Identification Number(TIN)',
                field: 'taxIdentificationNumber'
              },
              {
                title: 'Approved',
                render: rowData => (
                  <Checkbox
                    checked={rowData.approved}
                    onChange={event =>
                      updateCustomerAttribute({
                        approved: event.target.checked,
                        uid: rowData.uid
                      })
                    }
                  />
                )
              },
              {
                title: 'View',
                render: rowData => (
                  <Button
                    onClick={() => navigate(`../customer/${rowData.uid}`)}
                  >
                    View
                  </Button>
                )
              }
            ]}
            data={JSON.parse(JSON.stringify(b))}
            editable={{
              onRowAdd: async newData => {
                try {
                  await inviteEmployee(newData);
                  setNewlyAddedCustomers([...newlyAddedCustomers, newData]);
                } catch (e) {
                  alert(e);
                }
              }
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

CustomerList.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default CustomerList;

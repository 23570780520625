import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/lib/integration/react';
import {
  ReactReduxFirebaseProvider
} from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.css';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['firebase'],
  stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

const fbConfig = {
  apiKey: 'AIzaSyAyJRHNfsXuvqhav8AEX7k2oYG5FuQzISo',
  authDomain: 'no-logo-staging.firebaseapp.com',
  databaseURL: 'https://no-logo-staging.firebaseio.com',
  projectId: 'no-logo-staging',
  storageBucket: 'no-logo-staging.appspot.com',
  messagingSenderId: '470510793198',
  appId: '1:470510793198:web:0c9e4a2377f18aed771eff',
  measurementId: 'G-B4V6515S7K'
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true // Get custom claims along with the profile
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);
firebase.firestore();
firebase.functions();
firebase.storage();

// Create store with reducers and initial state
const initialState = {};
const store = createStore(pReducer);
const persistor = persistStore(store);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

ReactDOM.render((
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <PersistGate loading={<div>loading</div>} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </ReactReduxFirebaseProvider>
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();

import React, { useState } from 'react';
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid';

const useProjects = (callback) => {

  const auth = useSelector((state) => state.firebase.auth);

  const firestore = useFirestore();
  useFirestoreConnect(() => [`Users/${auth.uid}`]);

  useFirestoreConnect([{
    collection: 'Projects',
    where: [
      ['projectOwner', '==', auth.uid],
    ],
    // limit: 1,
    storeAs: "projects"
  }]);


  const projects = useSelector((state) => state.firestore.data.projects);
  console.log(projects);

  return {
    projects
  };
};

const useCreateProject = (callback) => {
  const firebase = useFirebase();
  const firestore = useFirestore();

  const [inputs, setInputs] = useState({ createdAt: new Date(), tags: [], businessRequirements: {} });
  const [products, setProducts] = useState([]);

  const auth = useSelector((state) => state.firebase.auth);
  const user = useSelector(
    ({ firestore: { data } }) => data.Users && data.Users[auth.uid]
  );

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();

      const batch = firestore.batch();

      const bidsCollectionRef = firestore.collection('Bids');

      const projectDocRef = firestore.collection('Projects').doc();

      const bidIDs = products.map((product) => {
        const bidDocRef = bidsCollectionRef.doc();
        const bidID = bidDocRef.id;
        batch.set(bidDocRef, { ...product, bidID, projectID: projectDocRef.id, ...inputs});
        return bidID;
      });

      batch.set(projectDocRef, { ...inputs, bidIDs, projectOwner: user.companyID, projectID: projectDocRef.id }, {merge: true});

      await batch.commit().catch((e) => alert(e));
      alert('Project Saved Successfully');
    }
  };

  const handleAddTag = (tag) => {
    const tagsCopy = inputs.tags;
    tagsCopy.push(tag);
    setInputs({ ...inputs, tags: tagsCopy });
  };

  const handleDeleteTag = (tag, index) => {
    const tagsCopy = inputs.tags;
    tagsCopy.splice(index, 1);
    setInputs({ ...inputs, tags: tagsCopy });
  };

  const handleDateChange = (date, name) => {
    const timestamp = date.getTime();
    setInputs((inputs) => ({ ...inputs, [name]: timestamp }));
  };

  const handleBusinessReqChange = (event) => {
    // event.persist();
    const businessRequirementsCopy = inputs.businessRequirements;
    businessRequirementsCopy[event.target.name] = event.target.checked;
    setInputs((inputs) => ({ ...inputs, businessRequirements: businessRequirementsCopy }));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const handleBudgetChange = (value) => {
    setInputs((inputs) => ({ ...inputs, budget: value }));
  };

  const handleAddProduct = () => {
    setProducts((products) => ([...products, {}]));
  };

  const handleProductRequirementsChange = (event, index) => {
    const { name, value } = event.target;
    const productRequirements = products[index];
    productRequirements[name] = value;
    const productsCopy = [...products];
    productsCopy[index] = productRequirements;
    setProducts(productsCopy);
  };

  const handlePriceChange = (value, index) => {
    const productRequirements = products[index];
    productRequirements.price = value;
    const productsCopy = [...products];
    productsCopy[index] = productRequirements;
    setProducts(productsCopy);
  };

  const uploadImage = async (event, index) => {
    const { files } = event.target;
    const file = files[0];

    const fileSnapshot = await firebase.storage()
      .ref(uuid())
      .put(file)
      .catch((error) => console.log(error));

    const productImageURI = await fileSnapshot.ref.getDownloadURL();

    const productRequirements = products[index];
    productRequirements['productImageURI'] = productImageURI;
    const productsCopy = [...products];
    productsCopy[index] = productRequirements;
    setProducts(productsCopy);
  };

  return {
    handleSubmit,
    handleInputChange,
    handleDateChange,
    handleAddTag,
    handleDeleteTag,
    handleBusinessReqChange,
    handleBudgetChange,
    handleAddProduct,
    handleProductRequirementsChange,
    handlePriceChange,
    uploadImage,
    inputs,
    products
  };
};

export { useProjects, useCreateProject };

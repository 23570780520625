import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Paper
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import ChipInput from 'material-ui-chip-input';
import DateFnsUtils from '@date-io/date-fns';
import { useCreateProject, useBids } from './bidslist.hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import Advertisement_Banner_1 from '../../../assets/home/Hero_Banner_New.png';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  container: {
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 40
  },
  componentTitleBox: {
    marginBottom: 50
  },
  addCircleIcon: {
    marginRight: 10,
    color: 'rgb(106, 106, 106)'
  },
  productContainer: {},
  productListContainer: {
    paddingTop: 30
  },
  dialog: {
    width: '100%',
    height: '100%'
  },
  documentRequirementContainer: {
    height: '100%',
    width: '100%'
  },
  productNameText: {
    fontFamily: 'Poppins-Bold',
    fontSize: 20
  },
  statusText: {
    color: 'red',
    fontFamily: 'Poppins-Medium',
    fontSize: 15,
    paddingBottom: 20
  },
  priceText: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    paddingTop: 2.5,
    paddingBottom: 2.5
  }
}));

function capitalizeFirstLetter(string) {
  if(!string) return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ProductCard = ({ bidID, projectID }) => {
  useFirestoreConnect([
    {
      collection: `Projects/${projectID}/Bids/${bidID}/Products`,
      storeAs: `products-${bidID}`
    }
  ]);

  const products = useSelector(
    state => state.firestore.data[`products-${bidID}`]
  );

  console.log(products);
  console.log(bidID);
  console.log(projectID);
  if (!products) return null;
  return (
    <Carousel>
      {Object.entries(products)?.map(([key, value]) => (
        <Paper>
          <Typography>
            {`${value.quantity} ${value.productName} @ `}
            <NumberFormat
              value={value.price}
              displayType="text"
              thousandSeparator
              prefix="₱"
            />
          </Typography>

          <img
            src={value.productImageURI}
            style={{ width: '100%', objectFit: 'contain' }}
          />
        </Paper>
      ))}
    </Carousel>
  );
};

const BidCard = ({ data, type }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    handlePriceChange,
    handleInputChange,
    handleDateChange,
    handleDeleteTag,
    handleAddTag,
    handleBusinessReqChange,
    handleBudgetChange,
    handleAddProduct,
    handleProductRequirementsChange,
    uploadImage,
    inputs,
    products
  } = useCreateProject();

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
      >
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent>
          <Grid
            xs={12}
            md={10}
            style={{ width: '100vw' }}
            className={classes.projectDetailInput}
          >
            <Card className={classes.documentRequirementContainer}>
              <CardContent>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid xs={12} md={6} className={classes.projectDetailInput}>
                      <Grid
                        xs={12}
                        md={6}
                        className={classes.projectDetailInput}
                      >
                        <TextField
                          variant="outlined"
                          label="Bid Name"
                          name="bidName"
                          onChange={handleInputChange}
                          value={inputs.bidName || ''}
                          fullWidth
                        />
                      </Grid>
                      <NumberFormat
                        onValueChange={values => {
                          handleBudgetChange(values.floatValue);
                        }}
                        customInput={TextField}
                        prefix="₱"
                        thousandSeparator
                        fullWidth
                        name="budget"
                        variant="outlined"
                        label="Budget"
                        value={inputs.budget}
                      />
                    </Grid>
                    <Grid xs={12} md={6} className={classes.projectDetailInput}>
                      <KeyboardDatePicker
                        variant="outlined"
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        id="deliveryDate"
                        label="Delivery Date"
                        value={
                          inputs.deliveryDate
                            ? new Date(inputs.deliveryDate)
                            : null
                        }
                        onChange={date =>
                          handleDateChange(date, 'deliveryDate')
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12} md={6} className={classes.projectDetailInput}>
                      <KeyboardDatePicker
                        variant="outlined"
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        id="bidEndDate"
                        label="Bid End Date"
                        value={
                          inputs.bidEndDate ? new Date(inputs.bidEndDate) : null
                        }
                        onChange={date => handleDateChange(date, 'bidEndDate')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12} md={6} className={classes.projectDetailInput}>
                      <TextField
                        variant="outlined"
                        label="Delivery Place"
                        name="deliveryPlace"
                        onChange={handleInputChange}
                        value={inputs.deliveryPlace || ''}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={12} md={6} className={classes.projectDetailInput}>
                      <ChipInput
                        variant="outlined"
                        fullWidth
                        value={inputs.tags}
                        label="Tags"
                        onAdd={handleAddTag}
                        onDelete={handleDeleteTag}
                      />
                    </Grid>
                    <Grid xs={12} md={6} className={classes.labelContainer}>
                      <Typography variant="subtitle1" className={classes.label}>
                        Business Documents Requirements
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={6} className={classes.projectDetailInput}>
                      <Card className={classes.documentRequirementContainer}>
                        <CardContent>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Grid
                              xs={12}
                              md={6}
                              justify="center"
                              alignItems="center"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="sec"
                                    checked={
                                      inputs.businessRequirements.sec || false
                                    }
                                    onChange={handleBusinessReqChange}
                                  />
                                }
                                label="SEC"
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="or"
                                    checked={
                                      inputs.businessRequirements.or || false
                                    }
                                    onChange={handleBusinessReqChange}
                                  />
                                }
                                label="OR"
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="dti"
                                    checked={
                                      inputs.businessRequirements.dti || false
                                    }
                                    onChange={handleBusinessReqChange}
                                  />
                                }
                                label="DTI"
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="businessPermit"
                                    checked={
                                      inputs.businessRequirements
                                        .businessPermit || false
                                    }
                                    onChange={handleBusinessReqChange}
                                  />
                                }
                                label="Business Permit"
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="bir2303"
                                    checked={
                                      inputs.businessRequirements.bir2303 ||
                                      false
                                    }
                                    onChange={handleBusinessReqChange}
                                  />
                                }
                                label="BIR 2303"
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="dr"
                                    checked={
                                      inputs.businessRequirements.dr || false
                                    }
                                    onChange={handleBusinessReqChange}
                                  />
                                }
                                label="D.R."
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    {products.map((product, productIndex) => {
                      return (
                        <React.Fragment key={productIndex}>
                          <Grid
                            xs={12}
                            md={6}
                            className={classes.labelContainer}
                          >
                            <Typography
                              variant="subtitle1"
                              className={classes.label}
                            >
                              Product Requirements
                            </Typography>
                          </Grid>
                          <Grid
                            xs={12}
                            md={10}
                            className={classes.projectDetailInput}
                          >
                            <Card
                              className={classes.documentRequirementContainer}
                            >
                              <CardContent>
                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                >
                                  <Grid
                                    xs={12}
                                    md={3}
                                    justify="center"
                                    alignItems="center"
                                  >
                                    <Avatar
                                      variant="square"
                                      src={product.productImageURI}
                                      className={classes.productImage}
                                    >
                                      Product Image
                                    </Avatar>
                                    <Button component="label">
                                      Add Product Image
                                      <input
                                        onChange={event =>
                                          uploadImage(event, productIndex)
                                        }
                                        type="file"
                                        style={{ display: 'none' }}
                                      />
                                    </Button>
                                  </Grid>
                                  <Grid
                                    xs={12}
                                    md={9}
                                    item
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                  >
                                    <Grid>
                                      <Grid
                                        xs={12}
                                        className={classes.projectDetailInput}
                                      >
                                        <FormControl
                                          variant="outlined"
                                          fullWidth
                                        >
                                          <InputLabel>Product Type</InputLabel>
                                          <Select
                                            name="productType"
                                            value={
                                              products[productIndex]
                                                .productType || ''
                                            }
                                            onChange={event =>
                                              handleProductRequirementsChange(
                                                event,
                                                productIndex
                                              )
                                            }
                                          >
                                            <MenuItem value="Product">
                                              Product
                                            </MenuItem>
                                            <MenuItem value="Service">
                                              Service
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        className={classes.projectDetailInput}
                                      >
                                        <TextField
                                          variant="outlined"
                                          label="Product Name"
                                          name="productName"
                                          value={
                                            products[productIndex]
                                              .productName || ''
                                          }
                                          onChange={event =>
                                            handleProductRequirementsChange(
                                              event,
                                              productIndex
                                            )
                                          }
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        className={classes.projectDetailInput}
                                      >
                                        <TextField
                                          variant="outlined"
                                          label="Quantity"
                                          type="number"
                                          fullWidth
                                          name="quantity"
                                          value={
                                            products[productIndex].quantity ||
                                            ''
                                          }
                                          onChange={event =>
                                            handleProductRequirementsChange(
                                              event,
                                              productIndex
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        className={classes.projectDetailInput}
                                      >
                                        <TextField
                                          variant="outlined"
                                          label="Specifications"
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        className={classes.projectDetailInput}
                                      >
                                        <NumberFormat
                                          onValueChange={values => {
                                            handlePriceChange(
                                              values.floatValue,
                                              productIndex
                                            );
                                          }}
                                          customInput={TextField}
                                          prefix="₱"
                                          thousandSeparator
                                          fullWidth
                                          name="price"
                                          variant="outlined"
                                          label="Price"
                                          value={
                                            products[productIndex].price || ''
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </React.Fragment>
                      );
                    })}

                    <Grid
                      xs={12}
                      md={10}
                      className={classes.projectDetailInput}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.addNewProductButton}
                        onClick={handleAddProduct}
                      >
                        <AddCircleIcon className={classes.buttonIcon} />
                        <Typography
                          variant="h6"
                          className={classes.buttonLabel}
                        >
                          Add New Product
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </CardContent>
            </Card>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Card className={classes.container}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3} md={2}>
              <Typography
                align="left"
                variant="h5"
                className={classes.componentTitle}
              >
                {/*{`${type} List`}*/}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              {location.pathname?.includes('my/project/') &&
              <>
                <Button fullWidth onClick={() => setAddDialogOpen(true)}>
                  <AddCircleIcon className={classes.addCircleIcon}/>
                  <Typography variant="h6" className={classes.componentTitle}>
                    {`Add ${type}`}
                  </Typography>
                </Button>
              </>
              }
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="flex-start"
            className={classes.productListContainer}
            onClick={() => null}
          >
            {!!data &&
              Object.entries(data)?.map(([productID, project]) => {
                return (
                  <Grid
                    xs={12}
                    md={4}
                    lg={3}
                    className={classes.productContainer}
                    key={productID}
                  >
                    {location.pathname !== '/rfq' && (
                      <Typography
                        variant="h5"
                        className={classes.statusText}
                      >
                        {`STATUS: ${capitalizeFirstLetter(project.approved) || 'Waiting for Approval'}`}
                      </Typography>
                    )}

                    <Typography
                      variant="h3"
                      className={classes.productNameText}
                    >
                      {project.bidName}
                    </Typography>
                    <Typography variant="h6" className={classes.priceText}>
                      {`Budget:  `}
                      <NumberFormat
                        value={project.budget}
                        displayType="text"
                        thousandSeparator
                        prefix="₱"
                      />
                    </Typography>
                    <Grid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            name="dti"
                            checked={
                              project?.businessRequirements?.dti || false
                            }
                            onChange={handleBusinessReqChange}
                          />
                        }
                        label="DTI"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            name="dti"
                            checked={project?.businessRequirements?.or || false}
                            onChange={handleBusinessReqChange}
                          />
                        }
                        label="O.R."
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            name="dti"
                            checked={
                              project?.businessRequirements?.sec || false
                            }
                            onChange={handleBusinessReqChange}
                          />
                        }
                        label="SEC"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            name="dti"
                            checked={
                              project?.businessRequirements?.bir2303 || false
                            }
                            onChange={handleBusinessReqChange}
                          />
                        }
                        label="BIR 2303"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            name="dti"
                            checked={project?.businessRequirements?.dr || false}
                            onChange={handleBusinessReqChange}
                          />
                        }
                        label="DR"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            name="dti"
                            checked={
                              project?.businessRequirements?.businessPermit ||
                              false
                            }
                            onChange={handleBusinessReqChange}
                          />
                        }
                        label="Business Permit"
                      />
                    </Grid>
                    <Grid>
                      <Typography variant="h6" className={classes.priceText}>
                        {`Delivery Date:  ${
                          project.deliveryDate
                            ? new Date(
                                project.deliveryDate
                              ).toLocaleDateString()
                            : 'Not Available'
                        }`}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="h6" className={classes.priceText}>
                        {`Bid End Date:  ${
                          project.bidEndDate
                            ? new Date(project.bidEndDate).toLocaleDateString()
                            : 'Not Available'
                        }`}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="h6" className={classes.priceText}>
                        {`Delivery Place:  ${
                          project.deliveryPlace
                            ? project.deliveryPlace
                            : 'Not Available'
                        }`}
                      </Typography>
                    </Grid>
                    <Grid>
                      <ChipInput
                        disabled={true}
                        fullWidth
                        value={project?.tags || []}
                      />
                    </Grid>

                    <Grid style={{ paddingTop: 20 }}>
                      <Typography variant="h6" className={classes.priceText}>
                        {`Products`}
                      </Typography>
                      <ProductCard
                        bidID={project.bidID}
                        projectID={project.projectID}
                        key={productID}
                      />
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {location.pathname === '/rfq' && (
                        <Button
                          variant={'outlined'}
                          fullWidth
                          onClick={() =>
                            navigate(`../submit-bid/${project.bidID}/${project.projectID}`)
                          }
                        >
                          Create Proposal
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

BidCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default BidCard;

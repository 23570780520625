import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Page from 'src/components/Page';
import { useFirebase } from 'react-redux-firebase';
import Carousel from 'react-material-ui-carousel';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Background1 from '../../assets/login/Login_Banner.png';
import Advertisement_Banner_1 from '../../assets/home/Hero_Banner_New.png';
import ABOUT_US_STORY_IMAGE from '../../assets/aboutus/ABOUT_US_STORY_IMAGE.png';
import KNOWLEDGE_HUB_HERO from '../../assets/knowledgehub/KNOWLEDGE_HUB_HERO.png';
import NEWS_1 from '../../assets/knowledgehub/NEWS_1.png';
import NEWS_2 from '../../assets/knowledgehub/NEWS_2.png';
import NEWS_3 from '../../assets/knowledgehub/NEWS_3.png';
import NEWS_4 from '../../assets/knowledgehub/NEWS_4.png';
import WHY_PARTNER_WITH_US from '../../assets/aboutus/WHY_PARTNER_WITH_US.png';
import DiverseHands from '../../assets/home/DIVERSE_HANDS.png';
import App_Logo from '../../assets/shared/App_Logo.png';
import handsWithLeaf from '../../assets/home/icons/hands-with-leaf.png';
import medicine from '../../assets/home/icons/medicine.png';
import time from '../../assets/home/icons/time.png';
import waterBottle from '../../assets/home/icons/waterbottle.png';
import waterDroplets from '../../assets/home/icons/waterdroplets.png';
import webpage from '../../assets/home/icons/webpage.png';
import flatScreenTv from '../../assets/home/icons/flat-screen-v.png';
import tennisRacquet from '../../assets/home/icons/tennis-racquet.png';
import stethoscope from '../../assets/home/icons/stethoscope.png';
import recommended from '../../assets/home/icons/recoemmended.png';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    // backgroundImage: `url(require("src/assets/login/Login_Banner.png"))`,
    background: `url(${Background1})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(10, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#9FC6DD',
    color: 'white'
  },
  checkBox: {
    margin: theme.spacing(3, 0, 2)
  },
  textField: {
    background: 'white'
    // margin: theme.spacing(2, 0, 1),
  },
  divider: {
    width: '100%',
    backgroundColor: '#D8D8D8'
  },
  createAccountText: {
    color: '#9FC6DD'
  },
  button: {
    margin: theme.spacing(3, 0, 2)
  },
  appBar: {
    backgroundColor: 'rgb(34,77,107)',
    height: 175,
    width: '100vw',
    top: 0
  },
  logo: {
    paddingLeft: 52
  },
  topHalfButtons: {
    marginRight: 27,
    color: 'white',
    fontSize: 25,
    fontFamily: 'Poppins-Medium'
  },
  lowerHalfButtons: {
    // marginLeft: 27,
    marginRight: 27,
    color: 'white',
    fontSize: 25,
    fontWeight: 600,
    fontFamily: 'Poppins-Bold'
  },
  topButtonsContainer: {
    // height: 100,
    paddingRight: 52,
    paddingTop: 42
  },
  lowerButtonsContainer: {
    // height: 100,
    paddingRight: 52,
    paddingBottom: 27,
    paddingTop: 31
  },
  body: {
    width: '100vw'
  },
  searchBar: {
    backgroundColor: 'white'
  },
  howItWorksTitle: {
    color: 'rgb(34, 77, 107)',
    fontSize: 80,
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'Poppins-Bold'
  },
  howItWorksBody: {
    color: 'rgb(34, 77, 107)',
    fontSize: 38,
    fontWeight: 350,
    textAlign: 'center',
    fontFamily: 'Poppins-Regular'
  },
  learnMoreButton: {
    backgroundColor: 'rgb(75, 171, 229)',
    borderRadius: 100,
    width: '55%',
    color: 'white',
    fontSize: 27,
    fontFamily: 'Poppins-Regular'
  },
  arrowButton: {
    marginLeft: 60
  },
  footer: {
    color: 'white',
    fontSize: 25
  },
  socialMediaIcons: {
    color: 'white'
  },
  logoImage: {
    height: 170,
    width: 170,
    objectFit: 'contain',
    maxHeight: '100%'
  },
  marginRightZero: {
    marginRight: '0px !important'
  },
  vendorBayStoryDescription: {
    color: '#2F4E6B',
    fontSize: 20.83,
    fontFamily: 'Poppins',
    paddingBottom: 20
  }
}));

const POPULAR_SEARCHES = [
  { title: 'PACKAGING', icon: waterBottle },
  { title: 'ELECTRONICS', icon: flatScreenTv },
  { title: 'HOBBIES & INTERESTS', icon: tennisRacquet },
  { title: 'CHEMICALS', icon: waterDroplets },
  { title: 'ECO SAVER', icon: handsWithLeaf },
  { title: 'HEALTH & BEAUTY', icon: medicine },
  { title: 'ALL CATEGORIES', icon: webpage },
  { title: 'LATEST DEALS', icon: time },
  { title: 'RECOMMENDED', icon: recommended }
];

function Item(props) {
  return (
    <Paper>
      <img
        src={Advertisement_Banner_1}
        style={{ width: '100%', objectFit: 'contain' }}
      />
    </Paper>
  );
}

const KnowledgeHubpage = () => {
  const firebase = useFirebase();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogin = useCallback(async () => {
    await firebase
      .login({
        email: 'juddguerrero@gmail.com',
        password: '99Fbrxs11'
      })
      .catch(e => console.log(e));

    navigate('/app/dashboard', { replace: true });
  }, [firebase]);

  const items = [
    {
      name: 'Random Name #1',
      description: 'Probably the most random thing you have ever seen!'
    },
    {
      name: 'Random Name #2',
      description: 'Hello World!'
    }
  ];

  return (
    <Page className={classes.root} title="Login">
      <Grid container component="main" className={classes.root}>
        <Grid className={classes.body}>
          <Grid
            xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid xs={11} style={{ backgroundColor: 'rgb(248,248,248)' }}>
              <Grid
                container
                xs={12}
                justify="space-evenly"
                alignItems="center"
                style={{ backgroundColor: 'white' }}
              >
                <Grid xs={12} container>
                  <Grid
                    xs={12}
                    container
                    justify="center"
                    style={{ paddingTop: 50 }}
                  >
                    <Grid xs={12} lg={4} container alignItems="center">
                      <Typography
                        style={{
                          color: 'rgb(34, 77, 107)',
                          fontSize: 50,
                          fontWeight: 600,
                          fontFamily: 'Poppins-Bold',
                          paddingBottom: 20,
                          textAlign: 'justify'
                        }}
                      >
                        KNOWLEDGE HUB
                      </Typography>
                    </Grid>

                    <Grid xs={12} lg={6} container alignItems="center">
                      <Typography
                        style={{
                          color: 'rgb(34, 77, 107)',
                          fontSize: 25,
                          fontWeight: 600,
                          fontFamily: 'Poppins-Regular',
                          paddingBottom: 20,
                          textAlign: 'left'
                        }}
                      >
                        Let’s grow together! Learn the latest in the industry,
                        get free tips and inspiration for your next big
                        undertaking.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12} container>
                  <Grid
                    xs={12}
                    container
                    justify="center"
                    style={{ paddingTop: 50 }}
                  >
                    <Grid
                      xs={12}
                      lg={12}
                      container
                      alignItems="center"
                      justify={'center'}
                    >
                      <img
                        src={KNOWLEDGE_HUB_HERO}
                        style={{ width: '100%', objectFit: 'contain' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12} style={{
                  background: 'linear-gradient(to bottom, #ffffff 0%, #ffffff 5%, #FBF1AF 5%, #FBF1AF 95%, #ffffff 95%, #ffffff 100%)' }}>
                  <Grid
                    xs={12}
                    justify="flex-end"
                    container
                    style={{ width: '100vw' }}
                  >
                    <Grid
                      xs={8}
                      container
                      justify="space-between"
                      style={{
                        paddingTop: 50,
                        borderRadius: 25,
                        border: '5px solid rgb(34, 77, 105)',
                        paddingRight: 100,
                        paddingLeft: 100
                      }}
                    >
                      <Grid
                        xs={12}
                        lg={5}
                        container
                        alignItems="center"
                        justify={'center'}
                      >
                        <img
                          src={NEWS_1}
                          style={{ width: '100%', objectFit: 'contain' }}
                        />
                      </Grid>

                      <Grid
                        xs={12}
                        lg={5}
                        container
                        alignItems="center"
                        justify={'center'}
                      >
                        <img
                          src={NEWS_2}
                          style={{ width: '100%', objectFit: 'contain' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    justify="flex-start"
                    container
                    style={{ width: '100vw', paddingTop: 60, paddingBottom : 60 }}
                  >
                    <Grid
                      xs={8}
                      container
                      justify="space-between"
                      style={{
                        paddingTop: 50,
                        borderRadius: 25,
                        border: '5px solid rgb(34, 77, 105)',
                        paddingRight: 100,
                        paddingLeft: 100
                      }}
                    >
                      <Grid
                        xs={12}
                        lg={5}
                        container
                        alignItems="center"
                        justify={'center'}
                      >
                        <img
                          src={NEWS_3}
                          style={{ width: '100%', objectFit: 'contain' }}
                        />
                      </Grid>

                      <Grid
                        xs={12}
                        lg={5}
                        container
                        alignItems="center"
                        justify={'center'}
                      >
                        <img
                          src={NEWS_4}
                          style={{ width: '100%', objectFit: 'contain' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            direction="row"
            style={{ backgroundColor: 'rgb(32, 76, 106)' }}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid xs={11} container>
              <Grid xs={5} item style={{ padding: 60, paddingLeft: 140 }}>
                <Typography className={classes.footer}>Get in touch</Typography>
                <Typography className={classes.footer}>
                  Vendorbay, Inc.
                </Typography>
                <Typography className={classes.footer}>
                  129 Apo St., Sta Mesa Heights
                </Typography>
                <Typography className={classes.footer}>
                  Quezon City,1100,Metro Manila,
                </Typography>
                <Typography className={classes.footer}>Philippines</Typography>
                <Typography className={classes.footer}>
                  02-8-000-0000
                </Typography>
              </Grid>

              <Grid xs={5} item style={{ padding: 60 }}>
                <Typography className={classes.footer}>INFORMATION</Typography>
                <Typography className={classes.footer}>FAQs</Typography>
                <Typography className={classes.footer}>Payment</Typography>
                <Typography className={classes.footer}>Delivery</Typography>
                <Typography className={classes.footer}>
                  Security and Privacy Policy
                </Typography>
                <Typography className={classes.footer}>
                  Terms and Conditions
                </Typography>
              </Grid>

              <Grid xs={2} style={{ position: 'relative' }}>
                <Grid style={{ position: 'absolute', bottom: 60 }}>
                  <IconButton
                    type="submit"
                    className={classes.socialMediaIcons}
                    aria-label="search"
                  >
                    <InstagramIcon />
                  </IconButton>
                  <IconButton
                    type="submit"
                    className={classes.socialMediaIcons}
                    aria-label="search"
                  >
                    <FacebookIcon />
                  </IconButton>
                  <IconButton
                    type="submit"
                    className={classes.socialMediaIcons}
                    aria-label="search"
                  >
                    <TwitterIcon />
                  </IconButton>
                  <IconButton
                    type="submit"
                    className={classes.socialMediaIcons}
                    aria-label="search"
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default KnowledgeHubpage;

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  Drawer,
  List,
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import { useNavigate } from 'react-router-dom';
import App_Logo from '../../assets/shared/App_Logo.png';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Background1 from '../../assets/login/Login_Banner.png';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    // backgroundImage: `url(require("src/assets/login/Login_Banner.png"))`,
    background: `url(${Background1})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(10, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#9FC6DD',
    color: 'white'
  },
  checkBox: {
    margin: theme.spacing(3, 0, 2)
  },
  textField: {
    background: 'white'
    // margin: theme.spacing(2, 0, 1),
  },
  divider: {
    width: '100%',
    backgroundColor: '#D8D8D8'
  },
  createAccountText: {
    color: '#9FC6DD'
  },
  button: {
    margin: theme.spacing(3, 0, 2)
  },
  appBar: {
    backgroundColor: 'rgb(34,77,107)',
    height: 175,
    // width: '100vw',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem'
    }
  },
  logo: {
    paddingLeft: 52
  },
  logoMobile: {},
  topHalfButtons: {
    marginRight: 27,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7rem'
    },
    fontFamily: 'Poppins-Medium'
  },
  lowerHalfButtons: {
    // marginLeft: 27,
    marginRight: 27,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7rem'
    },
    fontWeight: 600,
    fontFamily: 'Poppins-Bold'
  },
  topButtonsContainer: {
    // height: 100,
    paddingRight: 52,
    paddingTop: 42
  },
  lowerButtonsContainer: {
    // height: 100,
    paddingRight: 52,
    paddingBottom: 27,
    paddingTop: 31
  },
  body: {
    width: '100vw'
  },
  searchBar: {
    backgroundColor: 'white'
  },
  howItWorksTitle: {
    color: 'rgb(34, 77, 107)',
    fontSize: 80,
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'Poppins-Bold'
  },
  howItWorksBody: {
    color: 'rgb(34, 77, 107)',
    fontSize: 38,
    fontWeight: 350,
    textAlign: 'center',
    fontFamily: 'Poppins-Regular'
  },
  learnMoreButton: {
    backgroundColor: 'rgb(75, 171, 229)',
    borderRadius: 100,
    width: '55%',
    color: 'white',
    fontSize: 27,
    fontFamily: 'Poppins-Regular'
  },
  arrowButton: {
    marginLeft: 60
  },
  footer: {
    color: 'white',
    fontSize: 25
  },
  socialMediaIcons: {
    color: 'white'
  },
  logoImage: {
    height: 170,
    width: 170,
    objectFit: 'contain',
    maxHeight: '100%'
  },
  marginRightZero: {
    marginRight: '0px !important'
  },
  menuIcon: {
    color: 'white',
    textAlign: 'right'
  },
  menuIconGrid: { textAlign: 'right' }
}));

const TopBar = ({ className, ...rest }) => {
  const navigate = useNavigate();

  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <Grid className={classes.appBar} container>
      <Grid container>

        {/* Mobile View Menu Bar*/}
        <Hidden mdUp>
          <Grid
            container
            direction={'row'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid xs={6} className={classes.logoMobile}>
              <img src={App_Logo} className={classes.logoImage} />
            </Grid>
            <Grid xs={6} className={classes.menuIconGrid}>
              <React.Fragment key={'top'}>
                <IconButton
                  size={'large'}
                  onClick={toggleDrawer('top', true)}
                  className={classes.menuIcon}
                >
                  <MenuIcon style={{ fontSize: 60 }} />
                </IconButton>
                <Drawer
                  anchor={'top'}
                  open={state['top']}
                  onClose={toggleDrawer('top', false)}
                >
                  <Box
                    sx={{
                      width:
                        'top' === 'top' || 'top' === 'bottom' ? 'auto' : 250
                    }}
                    role="presentation"
                    onClick={toggleDrawer('top', false)}
                    onKeyDown={toggleDrawer('top', false)}
                  >
                    <List>
                      {['Contact Us',
                        // 'My Account',
                        // 'Sign Up'
                      ].map(
                        (text, index) => (
                          <ListItem button key={text}>
                            <ListItemText primary={text} />
                          </ListItem>
                        )
                      )}
                    </List>
                    <Divider />
                    <List>
                      {['Home', 'About Us', 'Knowledge Hub'].map(
                        (text, index) => (
                          <ListItem button key={text}>
                            <ListItemText primary={text} />
                          </ListItem>
                        )
                      )}
                    </List>
                  </Box>
                </Drawer>
              </React.Fragment>
            </Grid>
          </Grid>
        </Hidden>

        {/*Desktop View Menu Bar*/}
        <Hidden xsDown>
          <Grid xs={0} md={2} className={classes.logo}>
            <img src={App_Logo} className={classes.logoImage} />
          </Grid>
          <Grid xs={12} lg={10} direction="column">
            {/*TOP HALF BUTTONS*/}
            <Grid
              xs={12}
              container
              alignItems="center"
              direction="row"
              justify="flex-end"
              className={classes.topButtonsContainer}
            >
              {/*<Grid>*/}
              {/*  <Typography className={classes.topHalfButtons}>*/}
              {/*    Contact Us*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}
              {/*<Grid>*/}
              {/*  <Button*/}
              {/*    style={{ textTransform: 'none' }}*/}
              {/*    onClick={() => navigate('/account')}*/}
              {/*  >*/}
              {/*    <Typography className={classes.topHalfButtons}>*/}
              {/*      My Account*/}
              {/*    </Typography>*/}
              {/*  </Button>*/}
              {/*</Grid>*/}
              {/*<Grid>*/}
              {/*  <Button*/}
              {/*    style={{ textTransform: 'none' }}*/}
              {/*    onClick={() => navigate('/register')}*/}
              {/*  >*/}
              {/*    <Typography*/}
              {/*      className={[*/}
              {/*        classes.topHalfButtons,*/}
              {/*        classes.marginRightZero*/}
              {/*      ]}*/}
              {/*    >*/}
              {/*      Sign Up*/}
              {/*    </Typography>*/}
              {/*  </Button>*/}
              {/*</Grid>*/}
              <Grid
                className={classes.searchBar}
                justify="flex-end"
                alignItems="center"
              >
                <InputBase variant="outlined" placeholder="Search..." />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/*LOWER HALF BUTTONS*/}
            <Grid
              xs={12}
              container
              alignItems="center"
              direction="row"
              justify="flex-end"
              className={classes.lowerButtonsContainer}
            >
              <Grid>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => navigate('/')}
                >
                  <Typography className={classes.lowerHalfButtons}>
                    Home
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => navigate('/about-us', { replace: true })}
                >
                  <Typography className={classes.lowerHalfButtons}>
                    About Us
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => navigate('/knowledge-hub', { replace: true })}
                >
                  <Typography
                    className={classes.lowerHalfButtons}
                    styles={{ marginRight: '0px !important' }}
                  >
                    Knowledge Hub
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  style={{ textTransform: 'none' }}
                >
                  <Typography className={classes.lowerHalfButtons}>
                    Contact Us
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => navigate('/account')}
                >
                  <Typography className={classes.lowerHalfButtons}>
                    My Account
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => navigate('/register')}
                >
                  <Typography
                    className={[
                      classes.topHalfButtons,
                      classes.marginRightZero
                    ]}
                  >
                    Sign Up
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Avatar, Checkbox, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Select, TextField,
  IconButton
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ChipInput from 'material-ui-chip-input';
import DateFnsUtils from '@date-io/date-fns';
import { useCreateProject } from './projectslist.hooks';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 40
  },
  componentTitleBox: {
    marginBottom: 50
  },
  addCircleIcon: {
    marginRight: 10,
    color: 'rgb(106, 106, 106)'
  },
  productContainer: {},
  productListContainer: {
    paddingTop: 30
  },
  dialog: {
  }
}));

const ProjectCard = ({ data, type }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    handlePriceChange,
    handleInputChange,
    handleDateChange,
    handleDeleteTag,
    handleAddTag,
    handleBusinessReqChange,
    handleBudgetChange,
    handleAddProduct,
    handleProductRequirementsChange,
    uploadImage,
    inputs,
    products
  } = useCreateProject();

  const navigate = useNavigate();

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  console.log(data);
  return (
    <>
      <Dialog
        className={classes.dialog}
        // fullScreen={true}
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
      >
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent>
          <Grid xs={12} md={10} style={{ width: 500 }} className={classes.projectDetailInput}>
            <Card className={classes.documentRequirementContainer}>
              <CardContent>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid xs={12} md={6} className={classes.projectDetailInput}>
                      <TextField
                        variant="outlined"
                        label="Project Name"
                        name="projectName"
                        onChange={handleInputChange}
                        value={inputs.projectName || ''}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </CardContent>
            </Card>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Card className={classes.container}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3} md={2}>
              <Typography
                align="left"
                variant="h5"
                className={classes.componentTitle}
              >
                {`${type} List`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button fullWidth onClick={() => setAddDialogOpen(true)}>
                <AddCircleIcon className={classes.addCircleIcon} />
                <Typography variant="h6" className={classes.componentTitle}>
                  {`Add ${type}`}
                </Typography>
              </Button>
            </Grid>
          </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.productListContainer}
              onClick={() => null}
            >
              {!!data
            && Object.entries(data)?.map(([projectID, project]) => {
              return (
                <Grid
                  xs={12}
                  md={4}
                  lg={3}
                  className={classes.productContainer}
                  key={projectID}
                >
                  <Button onClick={() => navigate(`../project/${projectID}`)}>
                    <Typography variant="h3" className={classes.productNameText}>
                      {project.projectName}
                    </Typography>
                  </Button>
                </Grid>
              );
            })}
            </Grid>
        </CardContent>
      </Card>
    </>
  );
};

ProjectCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProjectCard;

import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Hidden,
  TextField,
  InputLabel
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import startCase from 'lodash/startCase';
import Page from 'src/components/Page';
import Carousel from 'react-material-ui-carousel';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Background1 from '../../assets/login/Login_Banner.png';
import Advertisement_Banner_1 from '../../assets/home/Hero_Banner_New.png';
import HOW_IT_WORKS_1 from '../../assets/home/HOW_IT_WORKS_1.png';
import HOW_IT_WORKS_2 from '../../assets/home/HOW_IT_WORKS_2.png';
import HOW_IT_WORKS_3 from '../../assets/home/HOW_IT_WORKS_3.png';
import handsWithLeaf from '../../assets/home/icons/hands-with-leaf.png';
import medicine from '../../assets/home/icons/medicine.png';
import time from '../../assets/home/icons/time.png';
import waterBottle from '../../assets/home/icons/waterbottle.png';
import waterDroplets from '../../assets/home/icons/waterdroplets.png';
import webpage from '../../assets/home/icons/webpage.png';
import flatScreenTv from '../../assets/home/icons/flat-screen-v.png';
import tennisRacquet from '../../assets/home/icons/tennis-racquet.png';
import recommended from '../../assets/home/icons/recoemmended.png';
import get from 'lodash/get';
import { useFirestore } from 'react-redux-firebase';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20
  },
  image: {
    // backgroundImage: `url(require("src/assets/login/Login_Banner.png"))`,
    background: `url(${Background1})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(10, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#9FC6DD',
    color: 'white'
  },
  checkBox: {
    margin: theme.spacing(3, 0, 2)
  },
  textField: {
    background: 'white'
    // margin: theme.spacing(2, 0, 1),
  },
  divider: {
    width: '100%',
    backgroundColor: '#D8D8D8'
  },
  createAccountText: {
    color: '#9FC6DD'
  },
  button: {
    margin: theme.spacing(3, 0, 2)
  },
  appBar: {
    backgroundColor: 'rgb(34,77,107)',
    height: 175,
    width: '100vw',
    top: 0
  },
  logo: {
    paddingLeft: 52
  },
  topHalfButtons: {
    marginRight: 27,
    color: 'white',
    fontSize: 25,
    fontFamily: 'Poppins-Medium'
  },
  lowerHalfButtons: {
    // marginLeft: 27,
    marginRight: 27,
    color: 'white',
    fontSize: 25,
    fontWeight: 600,
    fontFamily: 'Poppins-Bold'
  },
  topButtonsContainer: {
    // height: 100,
    paddingRight: 52,
    paddingTop: 42
  },
  lowerButtonsContainer: {
    // height: 100,
    paddingRight: 52,
    paddingBottom: 27,
    paddingTop: 31
  },
  body: {
    width: '100vw'
  },
  searchBar: {
    backgroundColor: 'white'
  },
  howItWorksMainTitle: {
    color: 'rgb(34, 77, 107)',
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'Poppins-Bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.8rem'
    }
  },
  howItWorksTitle: {
    color: 'rgb(34, 77, 107)',
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'Poppins-Bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.8rem'
    }
  },
  howItWorksBody: {
    color: 'rgb(34, 77, 107)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.5rem'
    },
    fontWeight: 350,
    textAlign: 'center',
    fontFamily: 'Poppins-Regular',
    [theme.breakpoints.up('md')]: {
      height: 100
    }
  },
  learnMoreButton: {
    backgroundColor: 'rgb(75, 171, 229)',
    borderRadius: 100,
    width: '55%',
    color: 'white',
    fontSize: 27,
    fontFamily: 'Poppins-Regular',
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
      marginBottom: 30
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.3rem'
    }
  },
  arrowButton: {
    marginLeft: 60
  },
  footer: {
    color: 'white',
    fontSize: 25
  },
  socialMediaIcons: {
    color: 'white'
  },
  logoImage: {
    height: 170,
    width: 170,
    objectFit: 'contain',
    maxHeight: '100%'
  },
  marginRightZero: {
    marginRight: '0px !important'
  },
  popularSearchesGrid: {
    marginLeft: 50,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  popularSearchesTitle: {
    color: 'rgb(34, 77, 107)',
    fontSize: 40,
    fontWeight: 500,
    textAlign: 'left',
    fontFamily: 'Poppins-Bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem'
    },
    paddingBottom: 20
  },
  popularSearchesItems: {
    color: 'rgb(34, 77, 107)',
    fontWeight: 400,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.3rem'
    },
    fontFamily: 'Poppins-Medium'
  },
  enquiryFormGrid: {
    paddingBottom: 10
  },
  submitEnquiryButton: {
    backgroundColor: '#67A9E0',
    color: 'white',
    fontFamily: 'Poppins-Medium',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem'
    },
    marginBottom: 20
  }
}));

const POPULAR_SEARCHES = [
  { title: 'PACKAGING', icon: waterBottle },
  { title: 'ELECTRONICS', icon: flatScreenTv },
  { title: 'HOBBIES & INTERESTS', icon: tennisRacquet },
  { title: 'CHEMICALS', icon: waterDroplets },
  { title: 'ECO SAVER', icon: handsWithLeaf },
  { title: 'HEALTH & BEAUTY', icon: medicine },
  { title: 'ALL CATEGORIES', icon: webpage },
  { title: 'LATEST DEALS', icon: time },
  { title: 'RECOMMENDED', icon: recommended }
];

function Item(props) {
  return (
    <Paper>
      <img
        src={Advertisement_Banner_1}
        style={{ width: '100%', objectFit: 'contain' }}
      />
    </Paper>
  );
}

const Homepage = () => {
  const classes = useStyles();

  const items = [
    {
      name: 'Random Name #1',
      description: 'Probably the most random thing you have ever seen!'
    },
    {
      name: 'Random Name #2',
      description: 'Hello World!'
    }
  ];

  const validationSchema = yup.object({
    emailAddress: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    contactPerson: yup
      .string('Enter the name of the contact person')
      .required('Contact person is required'),
    contactNumber: yup
      .string('Enter a contact number')
      .required('Contact number is required')
  });

  const initialValues = {
    projectName: '',
    item: '',
    quantity: '',
    specs: '',
    emailAddress: '',
    contactPerson: '',
    contactNumber: ''
  };

  const firestore = useFirestore();

  const formik = useFormik({
    initialValues: {...initialValues, photo: ''},
    validationSchema,
    onSubmit: async values => {
      const userDocumentRef = firestore
        .collection('Enquiries')
        .doc();
      await userDocumentRef.set(values, { merge: true });
      alert(JSON.stringify(values, null, 2));
    }
  });

  return (
    <Page className={classes.root} title="Login">
      <Grid
        container
        component="main"
        className={classes.root}
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Grid
          className={classes.body}
          // md={12}
          xs={12}
        >
          {/* Carousel */}
          <Hidden xsDown>
            <Grid
              container
              justify="center"
              xs={12}
              // component={Paper}
              // elevation={6}
              // square
            >
              <Grid xs={12}>
                <Carousel>
                  {items.map((item, i) => (
                    <Item key={i} item={item} />
                  ))}
                </Carousel>
              </Grid>
            </Grid>
          </Hidden>
          <Grid
            xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid xs={11} style={{ backgroundColor: 'rgb(248,248,248)' }}>
              <Grid
                container
                xs={12}
                justify="space-evenly"
                alignItems="center"
                style={{ backgroundColor: 'rgb(248,248,248)' }}
              >
                <Typography className={classes.howItWorksMainTitle}>
                  HOW IT WORKS
                </Typography>
              </Grid>

              {/* Three Images */}
              <Grid
                container
                xs={12}
                justify="space-evenly"
                alignItems="center"
                style={{
                  backgroundColor: 'rgb(248,248,248)',
                  paddingBottom: 100
                }}
              >
                <Grid xs={12} container justify="space-evenly">
                  <Grid xs={12} lg={3} container alignItems="flex-start">
                    <Grid xs={12}>
                      <img
                        src={HOW_IT_WORKS_1}
                        style={{ width: '100%', objectFit: 'contain' }}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Typography className={classes.howItWorksTitle}>
                        PROCURE
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography className={classes.howItWorksBody}>
                        Search for the best suppliers through our premier vendor
                        database
                      </Typography>
                    </Grid>
                    <Grid xs={12} style={{ textAlign: 'center' }}>
                      <Button className={classes.learnMoreButton}>
                        learn more
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid xs={12} lg={3} container alignItems="center">
                    <Grid xs={12}>
                      <img
                        src={HOW_IT_WORKS_2}
                        style={{ width: '100%', objectFit: 'contain' }}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Typography className={classes.howItWorksTitle}>
                        SELL
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography className={classes.howItWorksBody}>
                        Offer your products and services to a network of
                        enterprises and emerging markets.
                      </Typography>
                    </Grid>
                    <Grid xs={12} style={{ textAlign: 'center' }}>
                      <Button className={classes.learnMoreButton}>
                        learn more
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid xs={12} lg={3} container alignItems="center">
                    <Grid xs={12}>
                      <img
                        src={HOW_IT_WORKS_3}
                        style={{ width: '100%', objectFit: 'contain' }}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Typography className={classes.howItWorksTitle}>
                        BID
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Typography className={classes.howItWorksBody}>
                        Gain access to exclusive project bidding and awarding by
                        megacorporations and other industries.
                      </Typography>
                    </Grid>
                    <Grid xs={12} style={{ textAlign: 'center' }}>
                      <Button className={classes.learnMoreButton}>
                        learn more
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {/* FOR MOBILE VIEW */}
                {/* <Grid xs={12} lg={3} container alignItems="center"> */}
                {/*  <Grid xs={12}> */}
                {/*    <img */}
                {/*      src={HOW_IT_WORKS_1} */}
                {/*      style={{ width: '100%', objectFit: 'contain' }} */}
                {/*    /> */}
                {/*  </Grid> */}
                {/*  <Grid xs={12}> */}
                {/*    <Grid xs={12}> */}
                {/*      <Typography className={classes.howItWorksTitle}> */}
                {/*        PROCURE */}
                {/*      </Typography> */}
                {/*    </Grid> */}
                {/*    <Grid xs={12}> */}
                {/*      <Typography className={classes.howItWorksBody}> */}
                {/*        Search for the best suppliers through our premier vendor */}
                {/*        database */}
                {/*      </Typography> */}
                {/*    </Grid> */}
                {/*    <Grid xs={12} style={{ textAlign: 'center' }}> */}
                {/*      <Button className={classes.learnMoreButton}> */}
                {/*        learn more */}
                {/*      </Button> */}
                {/*    </Grid> */}
                {/*  </Grid> */}
                {/* </Grid> */}

                {/* <Grid xs={12} lg={3} container alignItems="center"> */}
                {/*  <Grid xs={12}> */}
                {/*    <img */}
                {/*      src={HOW_IT_WORKS_2} */}
                {/*      style={{ width: '100%', objectFit: 'contain' }} */}
                {/*    /> */}
                {/*  </Grid> */}
                {/*  <Grid xs={12}> */}
                {/*    <Grid xs={12}> */}
                {/*      <Typography className={classes.howItWorksTitle}> */}
                {/*        SELL */}
                {/*      </Typography> */}
                {/*    </Grid> */}

                {/*    <Grid xs={12}> */}
                {/*      <Typography className={classes.howItWorksBody}> */}
                {/*        Offer your products and services to a network of */}
                {/*        enterprises and emerging markets. */}
                {/*      </Typography> */}
                {/*    </Grid> */}
                {/*    <Grid xs={12} style={{ textAlign: 'center' }}> */}
                {/*      <Button className={classes.learnMoreButton}> */}
                {/*        learn more */}
                {/*      </Button> */}
                {/*    </Grid> */}
                {/*  </Grid> */}
                {/* </Grid> */}

                {/* <Grid xs={12} lg={3} container alignItems="center"> */}
                {/*  <Grid xs={12}> */}
                {/*    <img */}
                {/*      src={HOW_IT_WORKS_3} */}
                {/*      style={{ width: '100%', objectFit: 'contain' }} */}
                {/*    /> */}
                {/*  </Grid> */}
                {/*  <Grid xs={12}> */}
                {/*    <Grid xs={12}> */}
                {/*      <Typography className={classes.howItWorksTitle}> */}
                {/*        BID */}
                {/*      </Typography> */}
                {/*    </Grid> */}
                {/*    <Grid xs={12}> */}
                {/*      <Typography className={classes.howItWorksBody}> */}
                {/*        Gain access to exclusive project bidding and awarding by */}
                {/*        megacorporation and other indusries */}
                {/*      </Typography> */}
                {/*    </Grid> */}
                {/*    <Grid xs={12} style={{ textAlign: 'center' }}> */}
                {/*      <Button className={classes.learnMoreButton}> */}
                {/*        join now */}
                {/*      </Button> */}
                {/*    </Grid> */}
                {/*  </Grid> */}
                {/* </Grid> */}
              </Grid>

              <Grid
                container
                xs={12}
                justify="space-evenly"
                alignItems="center"
                style={{ backgroundColor: 'white' }}
              >
                <Grid xs={12} container className={classes.popularSearchesGrid}>
                  <Grid md={10} xs={12}>
                    <Typography className={classes.popularSearchesTitle}>
                      POPULAR SEARCHES
                    </Typography>
                  </Grid>

                  <Grid xs={12} lg={6} container>
                    {POPULAR_SEARCHES.map(({ title, icon }) => (
                      <Grid xs={12} container>
                        <Grid xs={2}>
                          <IconButton
                            type="submit"
                            // className={classes.arrowButton}
                            aria-label="search"
                          >
                            <img
                              src={icon}
                              style={{ width: '100%', objectFit: 'contain' }}
                            />
                            {/* <ArrowForwardIosIcon /> */}
                          </IconButton>
                        </Grid>
                        <Grid md={4} xs={8}>
                          <Typography className={classes.popularSearchesItems}>
                            {title}
                          </Typography>
                        </Grid>
                        <Grid xs={2}>
                          <IconButton
                            type="submit"
                            // className={classes.arrowButton}
                            aria-label="search"
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid xs={12} lg={5}>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid md={10} xs={12}>
                        <Typography className={classes.popularSearchesTitle}>
                          Enquiry
                        </Typography>
                      </Grid>

                      {Object.keys(initialValues).map(value => (
                        <Grid xs={12} className={classes.enquiryFormGrid}>
                          <InputLabel
                            style={{ paddingBottom: 15, color: '#BEC1CF' }}
                          >
                            {startCase(value)}
                          </InputLabel>
                          <TextField
                            id={value}
                            variant="outlined"
                            name={value}
                            value={formik.values[value]}
                            onChange={formik.handleChange}
                            fullWidth
                            error={
                              formik.touched[value] &&
                              Boolean(formik.errors[value])
                            }
                            helperText={
                              formik.touched[value] && formik.errors[value]
                            }
                            style={{ width: '100%' }}
                          />
                        </Grid>
                      ))}

                      <Grid xs={12} className={classes.enquiryFormGrid}>
                        <InputLabel
                          style={{ paddingBottom: 15, color: '#BEC1CF' }}
                        >
                          Photo
                        </InputLabel>
                        <input
                          id="photo"
                          name="photo"
                          type="file"
                          onChange={event => {
                            formik.setFieldValue(
                              'photo',
                              event.currentTarget.files[0]
                            );
                          }}
                          className="form-control"
                        />
                      </Grid>

                      <Grid xs={12} className={classes.enquiryFormGrid}>
                        <Button
                          type={'submit'}
                          fullWidth
                          className={classes.submitEnquiryButton}
                        >
                          Request for quote
                        </Button>
                      </Grid>
                    </form>
                    {/* <img */}
                    {/*  src={RIGHT_OF_CATEGORIES} */}
                    {/*  style={{ width: '100%', objectFit: 'contain' }} */}
                    {/* /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Homepage;

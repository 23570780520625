import { styled } from '@material-ui/styles';
import StepConnector from '@material-ui/core/StepConnector';
import React from 'react';
import { Grid, Step, StepLabel, Stepper } from '@material-ui/core';

const ActiveIcon = ({ stepNumber }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97"
    height="97"
    viewBox="0 0 97 97"
  >
    <g id="Group_282" data-name="Group 282" transform="translate(-212 -766)">
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="48.5"
        cy="48.5"
        r="48.5"
        transform="translate(212 766)"
        fill="#61aae8"
      />
      <text
        id="_1"
        data-name="1"
        transform="translate(248.809 834.969)"
        fill="#fff"
        font-size="58.333"
        font-family="Poppins-Bold, Poppins"
        font-weight="700"
      >
        <tspan x="0" y="0">
          {stepNumber}
        </tspan>
      </text>
    </g>
  </svg>
);

const DisabledIcon = ({ stepNumber }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97"
    height="97"
    viewBox="0 0 97 97"
  >
    <g id="Group_283" data-name="Group 283" transform="translate(-667 -771)">
      <g
        id="Ellipse_3_copy"
        data-name="Ellipse 3 copy"
        transform="translate(667 771)"
        fill="none"
        stroke="#e4e2df"
        stroke-linecap="round"
        stroke-width="4.167"
        stroke-dasharray="0 0"
      >
        <circle cx="48.5" cy="48.5" r="48.5" stroke="none" />
        <circle cx="48.5" cy="48.5" r="46.417" fill="none" />
      </g>
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="40.5"
        cy="40.5"
        r="40.5"
        transform="translate(675 779)"
        fill="#dedcdc"
      />
      <text
        id="_2"
        data-name="2"
        transform="translate(698.809 839.969)"
        fill="#fff"
        font-size="58.333"
        font-family="Poppins-Bold, Poppins"
        font-weight="700"
      >
        <tspan x="0" y="0">
          {stepNumber}
        </tspan>
      </text>
    </g>
  </svg>
);


const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.MuiStepConnector-active`]: {
    [`& .MuiStepConnector-line`]: {
      backgroundColor: '#61AAE8'
    }
  },
  [`&.MuiStepConnector-completed`]: {
    [`& .MuiStepConnector-line`]: {
      backgroundColor: '#61AAE8'
    }
  },
  [`& .MuiStepConnector-line`]: {
    height: 20,
    border: 0,
    backgroundColor: '#F0F3F4',
    borderRadius: 1,
    marginTop: 30
  }
}));

const CustomStepIconComponentRoot = styled('div')(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 100,
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

function CustomStepIconComponent(props) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconComponentRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {active || completed ? (
        <ActiveIcon stepNumber={String(props.icon)} />
      ) : (
        <DisabledIcon stepNumber={String(props.icon)} />
      )}
    </CustomStepIconComponentRoot>
  );
}

const RegistrationStepper = ({ activeStep }) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      style={{ width: '100%' }}
      connector={<CustomConnector />}
    >
      {[1, 2, 3].map(label => (
        <Step key={label}>
          <StepLabel StepIconComponent={CustomStepIconComponent}>
            {''}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default RegistrationStepper;

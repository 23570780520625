import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Forum as ForumIcon,
  FileCopy as FileCopyIcon,
  Gavel as GavelIcon,
  Help as HelpIcon,
  Home as HomeIcon,
  Language as LanguageIcon,
  List as ListIcon,
  MonetizationOn as MonetizationOnIcon,
  People as PeopleIcon,
  ShoppingCart as ShoppingCartIcon
} from '@material-ui/icons';
// import {
//   DollarSign as DollarSignIcon,
//   Home as HomeIcon,
//   MessageSquare as MessageSquareIcon,
//   ShoppingCart as ShoppingCartIcon
// } from 'react-feather';
import { useFirebase } from 'react-redux-firebase';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};

const dashboardItems = [
  {
    title: 'DASHBOARD',
    disabled: true,
  },
  {
    href: '/app/home',
    icon: HomeIcon,
    title: 'Home'
  },
  {
    href: '/app/bids',
    icon: MonetizationOnIcon,
    title: 'Bids'
  },
  {
    href: '/app/chat',
    icon: ForumIcon,
    title: 'Chat'
  },
  {
    href: '/app/purchase',
    icon: ShoppingCartIcon,
    title: 'Purchase'
  },
  {
    href: '/app/products',
    icon: ListIcon,
    title: 'Products List'
  },
  {
    title: 'BIDS',
    disabled: true,
  },
  {
    href: '/app/bids-list',
    icon: GavelIcon,
    title: 'Bids List'
  },
  {
    href: '/app/create-bid',
    icon: AddCircleIcon,
    title: 'Create Bid'
  },
  {
    title: 'ADMIN',
    disabled: true,
  },
  {
    href: '/app/employees',
    icon: PeopleIcon,
    title: 'Employees'
  },
  {
    href: '/app/files',
    icon: FileCopyIcon,
    title: 'Files'
  },
  {
    href: '/app/website-profile',
    icon: LanguageIcon,
    title: 'Website'
  },
  {
    title: 'HELP',
    disabled: true,
  },
  {
    href: '/app/help',
    icon: HelpIcon,
    title: 'Help'
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 251
  },
  desktopDrawer: {
    backgroundColor: '#FFFFFF',
    width: 251,
    top: 65,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  itemTitle: { marginBottom: 25 }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const firebase = useFirebase();
  const navigate = useNavigate();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {/* <Divider /> */}
      <Box p={2}>
        <List>
          {dashboardItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              disabled={item.disabled}
            />
          ))}
        </List>
      </Box>
      <button onClick={async () => {
        await firebase.auth().signOut().catch((e) => console.log(e));
        navigate('/login');
      }}
      >
        Log Out
      </button>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import {
  isLoaded,
  useFirebase,
  useFirestore,
  useFirestoreConnect
} from 'react-redux-firebase';
import { v4 as uuid } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie/loading_animation';
import UPLOAD_ICON from '../../../assets/account/UPLOAD_ICON.png';
import RegistrationStepper from '../../../components/RegistrationStepper';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const profile = useSelector(state => state.firebase.profile);
  const navigate = useNavigate();

  const firebase = useFirebase();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map(file => (
    <Grid key={file.path}>
      <Typography
        style={{
          color: 'rgb(34, 77, 107)',
          fontSize: 12.95,
          fontFamily: 'Poppins-Regular',
          textAlign: 'center'
        }}
      >
        {file.path} -{file.size} bytes
      </Typography>
    </Grid>
  ));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const auth = useSelector(state => state.firebase.auth);

  const firestore = useFirestore();
  useFirestoreConnect(() => [`Users/${auth.uid}`]);

  const user = useSelector(
    ({ firestore: { data } }) => data.Users && data.Users[auth.uid]
  );

  const [values, setValues] = useState(
    user || {
      firstName: '',
      lastName: '',
      middleInitial: '',
      company: '',
      designation: '',
      companyAddress: '',
      contactNumber: '',
      files: []
    }
  );

  useEffect(() => {
    if (user) {
      setValues(user);
    }
  }, [user]);

  const saveDetails = async files => {
    const userDocumentRef = firestore
      .collection('Users')
      .doc(user.uid || profile.token.claims['sub']);

    const filesURIsPromises = await files.map(async file => {
      const fileSnapshot = await firebase
        .storage()
        .ref(uuid())
        .put(file)
        .catch(error => console.log(error));

      const productImageURI = await fileSnapshot.ref.getDownloadURL();
      return {
        productImageURI,
        name: file.name,
        path: file.path,
        size: file.size
      };
    });

    const filesURIs = await Promise.all(filesURIsPromises);

    const oldFiles = values.files || [];

    try {
      userDocumentRef
        .set({ ...values, files: [...oldFiles, ...filesURIs] }, { merge: true })
        .then(() => {
          // alert('Successfully saved your information');
          navigate('../complete-profile');
        });
    } catch (e) {
      console.log(e);
      alert('An error occured please try again');
    }
  };

  if (!isLoaded(auth) && !isLoaded(user)) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Page
      className={classes.root}
      title="Account"
      style={{ backgroundColor: 'white' }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3} justify="center">
          <RegistrationStepper activeStep={1} />
          <Grid item xs={12} md={9}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={6} xs={10}>
                <Typography
                  style={{
                    color: 'rgb(34, 77, 107)',
                    fontSize: 31.95,
                    fontFamily: 'Poppins-Medium',
                    textAlign: 'left'
                  }}
                >
                  Information
                </Typography>

                <Grid container spacing={3} style={{ paddingTop: 50 }}>
                  <Grid item xs={12}>
                    <InputLabel>Last Name</InputLabel>
                    <TextField
                      fullWidth
                      name="lastName"
                      onChange={handleChange}
                      required
                      value={values.lastName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>First Name</InputLabel>
                    <TextField
                      fullWidth
                      name="firstName"
                      onChange={handleChange}
                      required
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Middle Initial</InputLabel>
                    <TextField
                      fullWidth
                      name="middleInitial"
                      onChange={handleChange}
                      required
                      value={values.middleInitial}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Company</InputLabel>
                    <TextField
                      fullWidth
                      name="company"
                      onChange={handleChange}
                      required
                      value={values.company}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Designation</InputLabel>
                    <TextField
                      fullWidth
                      name="designation"
                      onChange={handleChange}
                      required
                      value={values.designation}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Company Address</InputLabel>
                    <TextField
                      fullWidth
                      name="companyAddress"
                      onChange={handleChange}
                      required
                      value={values.companyAddress}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Contact Number</InputLabel>
                    <TextField
                      fullWidth
                      name="contactNumber"
                      onChange={handleChange}
                      required
                      value={values.contactNumber}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid
                  item
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                >
                  {values?.files?.length > 0 && (
                    <Grid
                      {...getRootProps()}
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ paddingBottom: 100, paddingTop: 100 }}
                    >
                      {values.files.map(file => {
                        return (
                          <Grid key={file.path}>
                            <Typography
                              style={{
                                color: 'rgb(34, 77, 107)',
                                fontSize: 12.95,
                                fontFamily: 'Poppins-Regular',
                                textAlign: 'center'
                              }}
                            >
                              {file.path} -{file.size} bytes
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}

                  <Grid
                    {...getRootProps()}
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ paddingBottom: 100, paddingTop: 100 }}
                  >
                    <Grid
                      xs={3}
                      alignItems="center"
                      justifyContent="center"
                      style={{ marginBottom: 50 }}
                    >
                      <img
                        src={UPLOAD_ICON}
                        style={{ height: '100%', objectFit: 'contain' }}
                      />
                    </Grid>
                    <Grid xs={12} style={{ marginBottom: 50 }}>
                      <Typography
                        style={{
                          color: 'rgb(34, 77, 107)',
                          fontSize: 31.95,
                          fontFamily: 'Poppins-Regular',
                          textAlign: 'center'
                        }}
                      >
                        Drag files to upload
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <label
                        style={{
                          fontSize: 25,
                          fontFamily: 'Poppins-Medium',
                          textAlign: 'center',
                          backgroundColor: '#61AAE8',
                          color: 'white',
                          cursor: 'pointer',
                          padding: 15,
                          marginLeft: 50,
                          marginRight: 50,
                          borderRadius: 50
                        }}
                      >
                        Browse files
                        <input {...getInputProps()} />
                      </label>
                    </Grid>
                    {files.length > 0 && (
                      <Grid
                        {...getRootProps()}
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ paddingBottom: 100, paddingTop: 100 }}
                      >
                        {files}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            onClick={() => saveDetails(acceptedFiles)}
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default Account;

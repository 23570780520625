import React, { useEffect, useState } from 'react';
import {
  useFirestore,
  useFirebase,
  useFirestoreConnect
} from 'react-redux-firebase';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';

const useCompleteProfile = callback => {
  const firebase = useFirebase();
  const firestore = useFirestore();

  const profile = useSelector(state => state.firebase.profile);

  const [inputs, setInputs] = useState({
    companyName: '',
    taxIdentificationNumber: '',
    lineOfBusiness: '',
    categories: '',
    brandCarried: '',
    locationsServed: '',
    companyAddress: '',
    officialReceipt: {},
    birCertificate: {},
    proofOfAccountOwnership: {},
    catalogsAndPricelists: {}
  });
  const [files, setFiles] = useState({});

  const auth = useSelector(state => state.firebase.auth);

  useFirestoreConnect(() => [`Users/${auth.uid}`]);

  const user = useSelector(
    ({ firestore: { data } }) => data.Users && data.Users[auth.uid]
  );

  useEffect(() => {
    if (user) {
      setInputs(user);
    }
  }, [user]);

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();

      try {
        const filesURLsPromise = Object.entries(files).map(
          async ([fileName, file]) => {
            const fileSnapshot = await firebase
              .storage()
              .ref(uuid())
              .put(file)
              .catch(error => console.log(error));

            const productImageURI = await fileSnapshot.ref.getDownloadURL();

            return {
              [fileName]: {
                productImageURI,
                name: file.name,
                size: file.size
              }
            };
          }
        );

        const filesURIsArray = await Promise.all(filesURLsPromise);

        const filesURIs = {};

        filesURIsArray.forEach(fileURI => {
          const [key, value] = Object.entries(fileURI)[0];
          filesURIs[key] = value;
        });

        console.log(filesURIsArray);

        const userDocumentRef = firestore
          .collection('Users')
          .doc(profile.token.claims['sub']);
        await userDocumentRef.set({ ...inputs, ...filesURIs }, { merge: true });
      } catch (e) {
        console.log(e);
        alert('An error has occurred please try again later');
      }
    }
  };

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const getRegions = country => {
    if (!country) {
      return [];
    }
    return country[2].split('|').map(regionPair => {
      let [regionName, regionShortCode = null] = regionPair.split('~');
      return regionName;
    });
  };

  const handleNumberChange = (type, mobileNumber) => {
    setInputs(inputs => ({ ...inputs, [type]: mobileNumber }));
  };

  const handleFileChange = event => {

    const name = event.target.name;

    setFiles(files => ({
      ...files,
      [name]: event.target.files[0]
    }));
  };

  return {
    getRegions,
    handleSubmit,
    handleFileChange,
    handleNumberChange,
    handleInputChange,
    files,
    inputs
  };
};

export { useCompleteProfile };

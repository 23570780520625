import React, { useState } from 'react';
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';

const useBids = callback => {
  const auth = useSelector(state => state.firebase.auth);

  const firestore = useFirestore();

  const { projectID } = useParams();

  const user = useSelector(
    ({ firestore: { data } }) => data.Users && data.Users[auth.uid]
  );

  useFirestoreConnect(() => [`Users/${auth.uid}`]);

  useFirestoreConnect([
    {
      collection: projectID ? `Projects/${projectID}/Bids` : 'Bids',
      where: [
        projectID
          ? (['approved', '==', 'approved'], ['projectID', '==', projectID])
          : ['approved', '==', 'approved']
      ],
      // limit: 1,
      storeAs: 'bids'
    }
  ]);

  console.log(projectID);

  const bids = useSelector(state => state.firestore.data.bids);
  console.log(bids);

  return {
    bids
  };
};

const useCreateProject = callback => {
  const firebase = useFirebase();
  const firestore = useFirestore();

  const { projectID } = useParams();

  const [inputs, setInputs] = useState({
    createdAt: new Date(),
    tags: [],
    businessRequirements: {}
  });
  const [products, setProducts] = useState([]);

  const auth = useSelector(state => state.firebase.auth);
  const user = useSelector(
    ({ firestore: { data } }) => data.Users && data.Users[auth.uid]
  );

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();

      const batch = firestore.batch();

      // Save a copy to bids for easy access
      const bidsCollectionRef = firestore.collection('Bids');
      const bidDocRef = bidsCollectionRef.doc();
      const bidID = bidDocRef.id;

      batch.set(
        bidDocRef,
        { ...inputs, bidID, projectOwner: user.companyID, projectID },
        { merge: true }
      );

      const bidDocRefInProject = firestore.doc(
        `Projects/${projectID}/Bids/${bidID}`
      );
      batch.set(
        bidDocRefInProject,
        { ...inputs, bidID, projectOwner: user.companyID, projectID },
        { merge: true }
      );

      // Write to both projects/bids and bids collection
      products.forEach(product => {
        // Write in Products collection of Bids
        const productDocRefInBid = bidDocRef.collection('Products').doc();
        const productID = productDocRefInBid.id;
        batch.set(
          productDocRefInBid,
          {
            ...product,
            bidID,
            productID,
            ...inputs
          },
          { merge: true }
        );

        // Write in Products collection of Projects/Bids
        const productDocRefInProjectBid = bidDocRefInProject
          .collection('Products')
          .doc(productID);
        batch.set(
          productDocRefInProjectBid,
          {
            ...product,
            bidID,
            productID,
            ...inputs
          },
          { merge: true }
        );
      });

      await batch.commit().catch(e => alert(e));
      alert('Project Saved Successfully');
    }
  };

  const handleAddTag = tag => {
    const tagsCopy = inputs.tags;
    tagsCopy.push(tag);
    setInputs({ ...inputs, tags: tagsCopy });
  };

  const handleDeleteTag = (tag, index) => {
    const tagsCopy = inputs.tags;
    tagsCopy.splice(index, 1);
    setInputs({ ...inputs, tags: tagsCopy });
  };

  const handleDateChange = (date, name) => {
    const timestamp = date.getTime();
    setInputs(inputs => ({ ...inputs, [name]: timestamp }));
  };

  const handleBusinessReqChange = event => {
    // event.persist();
    const businessRequirementsCopy = inputs.businessRequirements;
    businessRequirementsCopy[event.target.name] = event.target.checked;
    setInputs(inputs => ({
      ...inputs,
      businessRequirements: businessRequirementsCopy
    }));
  };

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const handleBudgetChange = value => {
    setInputs(inputs => ({ ...inputs, budget: value }));
  };

  const handleAddProduct = () => {
    setProducts(products => [...products, {}]);
  };

  const handleProductRequirementsChange = (event, index) => {
    const { name, value } = event.target;
    const productRequirements = products[index];
    productRequirements[name] = value;
    const productsCopy = [...products];
    productsCopy[index] = productRequirements;
    setProducts(productsCopy);
  };

  const handlePriceChange = (value, index) => {
    const productRequirements = products[index];
    productRequirements.price = value;
    const productsCopy = [...products];
    productsCopy[index] = productRequirements;
    setProducts(productsCopy);
  };

  const uploadImage = async (event, index) => {
    const { files } = event.target;
    const file = files[0];

    const fileSnapshot = await firebase
      .storage()
      .ref(uuid())
      .put(file)
      .catch(error => console.log(error));

    const productImageURI = await fileSnapshot.ref.getDownloadURL();

    const productRequirements = products[index];
    productRequirements.productImageURI = productImageURI;
    const productsCopy = [...products];
    productsCopy[index] = productRequirements;
    setProducts(productsCopy);
  };

  return {
    handleSubmit,
    handleInputChange,
    handleDateChange,
    handleAddTag,
    handleDeleteTag,
    handleBusinessReqChange,
    handleBudgetChange,
    handleAddProduct,
    handleProductRequirementsChange,
    handlePriceChange,
    uploadImage,
    inputs,
    products
  };
};

export { useBids, useCreateProject };

import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Form from './Form';
import RegistrationStepper from '../../../components/RegistrationStepper';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerDetails = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Create Bid">
      <Container maxWidth="xl">
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} md={10}>
            <Form />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CustomerDetails;

import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Page from 'src/components/Page';
import { useFirebase } from 'react-redux-firebase';
import Carousel from 'react-material-ui-carousel';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Background1 from '../../assets/login/Login_Banner.png';
import Advertisement_Banner_1 from '../../assets/home/Hero_Banner_New.png';
import ABOUT_US_STORY_IMAGE from '../../assets/aboutus/ABOUT_US_STORY_IMAGE.png';
import WHY_PARTNER_WITH_US from '../../assets/aboutus/WHY_PARTNER_WITH_US.png';
import DiverseHands from '../../assets/home/DIVERSE_HANDS.png';
import App_Logo from '../../assets/shared/App_Logo.png';
import handsWithLeaf from '../../assets/home/icons/hands-with-leaf.png';
import medicine from '../../assets/home/icons/medicine.png';
import time from '../../assets/home/icons/time.png';
import waterBottle from '../../assets/home/icons/waterbottle.png';
import waterDroplets from '../../assets/home/icons/waterdroplets.png';
import webpage from '../../assets/home/icons/webpage.png';
import flatScreenTv from '../../assets/home/icons/flat-screen-v.png';
import tennisRacquet from '../../assets/home/icons/tennis-racquet.png';
import stethoscope from '../../assets/home/icons/stethoscope.png';
import recommended from '../../assets/home/icons/recoemmended.png';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    // backgroundImage: `url(require("src/assets/login/Login_Banner.png"))`,
    background: `url(${Background1})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(10, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#9FC6DD',
    color: 'white'
  },
  checkBox: {
    margin: theme.spacing(3, 0, 2)
  },
  textField: {
    background: 'white'
    // margin: theme.spacing(2, 0, 1),
  },
  divider: {
    width: '100%',
    backgroundColor: '#D8D8D8'
  },
  createAccountText: {
    color: '#9FC6DD'
  },
  button: {
    margin: theme.spacing(3, 0, 2)
  },
  appBar: {
    backgroundColor: 'rgb(34,77,107)',
    height: 175,
    width: '100vw',
    top: 0
  },
  logo: {
    paddingLeft: 52
  },
  topHalfButtons: {
    marginRight: 27,
    color: 'white',
    fontSize: 25,
    fontFamily: 'Poppins-Medium'
  },
  lowerHalfButtons: {
    // marginLeft: 27,
    marginRight: 27,
    color: 'white',
    fontSize: 25,
    fontWeight: 600,
    fontFamily: 'Poppins-Bold'
  },
  topButtonsContainer: {
    // height: 100,
    paddingRight: 52,
    paddingTop: 42
  },
  lowerButtonsContainer: {
    // height: 100,
    paddingRight: 52,
    paddingBottom: 27,
    paddingTop: 31
  },
  body: {
    width: '100vw'
  },
  searchBar: {
    backgroundColor: 'white'
  },
  howItWorksTitle: {
    color: 'rgb(34, 77, 107)',
    fontSize: 80,
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'Poppins-Bold'
  },
  howItWorksBody: {
    color: 'rgb(34, 77, 107)',
    fontSize: 38,
    fontWeight: 350,
    textAlign: 'center',
    fontFamily: 'Poppins-Regular'
  },
  learnMoreButton: {
    backgroundColor: 'rgb(75, 171, 229)',
    borderRadius: 100,
    width: '55%',
    color: 'white',
    fontSize: 27,
    fontFamily: 'Poppins-Regular'
  },
  arrowButton: {
    marginLeft: 60
  },
  footer: {
    color: 'white',
    fontSize: 25
  },
  socialMediaIcons: {
    color: 'white'
  },
  logoImage: {
    height: 170,
    width: 170,
    objectFit: 'contain',
    maxHeight: '100%'
  },
  marginRightZero: {
    marginRight: '0px !important'
  },
  vendorBayStoryDescription: {
    color: '#2F4E6B',
    fontSize: 20.83,
    fontFamily: 'Poppins',
    paddingBottom: 20
  }
}));

function Item(props) {
  return (
    <Paper>
      <img
        src={Advertisement_Banner_1}
        style={{ width: '100%', objectFit: 'contain' }}
      />
    </Paper>
  );
}

const AboutUspage = () => {
  const firebase = useFirebase();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogin = useCallback(async () => {
    await firebase
      .login({
        email: 'juddguerrero@gmail.com',
        password: '99Fbrxs11'
      })
      .catch(e => console.log(e));

    navigate('/app/dashboard', { replace: true });
  }, [firebase]);

  const items = [
    {
      name: 'Random Name #1',
      description: 'Probably the most random thing you have ever seen!'
    },
    {
      name: 'Random Name #2',
      description: 'Hello World!'
    }
  ];

  return (
    <Page className={classes.root} title="Login">
      <Grid container component="main" className={classes.root}>
        <Grid className={classes.body}>
          {/* Carousel */}
          <Grid
            container
            justify="center"
            xs={12}
            // component={Paper}
            // elevation={6}
            // square
          >
            <Grid xs={12}>
              <Carousel>
                {items.map((item, i) => (
                  <Item key={i} item={item} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid xs={11} style={{ backgroundColor: 'rgb(248,248,248)' }}>
              <Grid
                container
                xs={12}
                justify="space-evenly"
                alignItems="center"
                style={{ backgroundColor: 'white' }}
              >
                <Grid xs={12} container style={{}}>
                  <Grid xs={12} container justify="space-between">
                    <Grid xs={12} lg={2} container alignItems="center">
                      <Grid xs={12}>
                        <img
                          src={ABOUT_US_STORY_IMAGE}
                          style={{ height: '100%', objectFit: 'contain' }}
                        />
                      </Grid>
                    </Grid>

                    <Grid xs={12} lg={8} container alignItems="center">
                      <Grid xs={12}>
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 50,
                            fontWeight: 600,
                            fontFamily: 'Poppins-Bold',
                            paddingBottom: 20,
                            textAlign: 'center'
                          }}
                        >
                          THE VENDOR BAY STORY
                        </Typography>
                        <Typography
                          className={classes.vendorBayStoryDescription}
                        >
                          It’s a classic tale of problem-solving. In his years
                          of experience as a salesman, Vendor Bay founder Carlo
                          always finds himself helping clients look for the
                          right product or service. And throughout his career,
                          he noticed that whether it’s a huge enterprise or an
                          SME, the struggle is real when it comes to the
                          purchasing aspect of any business.
                        </Typography>
                        <Typography
                          className={classes.vendorBayStoryDescription}
                        >
                          Timelines, biddings, accreditations, logistics, and
                          paperwork all demand attention and with projects at
                          scale, naturally, procurement complexity also
                          increases.
                        </Typography>
                        <Typography
                          className={classes.vendorBayStoryDescription}
                        >
                          With this, Carlo built Vendor Bay as a straightforward
                          and quick-to-use web platform where entrepreneurs and
                          corporations can connect with guaranteed, vetted, and
                          accredited suppliers.
                        </Typography>
                        <Typography
                          className={classes.vendorBayStoryDescription}
                        >
                          Vendor Bay removes the complications associated with
                          purchasing. Its easy-to-understand interface allows
                          both buyers and sellers to achieve their business
                          goals in no time.
                        </Typography>
                        <Typography
                          className={classes.vendorBayStoryDescription}
                        >
                          Need to find a niche product manufacturer? Explore the
                          Procure section. Want to expand your current client
                          portfolio? Be one of our discoverable suppliers when
                          you sign up via our Sell and Bid sections.
                        </Typography>
                        <Typography
                          className={classes.vendorBayStoryDescription}
                        >
                          Vendor Bay can be a company’s outsourced purchasing
                          arm or the extension of an in-house procurement team.
                          In parallel, Vendor Bay helps suppliers worldwide
                          reach their clientele more cost-efficiently. It is
                          providing a win-win solution, indeed.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12} container style={{paddingBottom: 50}}>
                  <Grid xs={12} container justify="space-evenly">
                    <Grid xs={12} lg={5} container alignItems="center">
                      <Grid
                        xs={12}
                        style={{
                          background:
                            'linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #FFFFC8 50%, #FFFFC8 100%)'
                        }}
                      >
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 58,
                            fontWeight: 600,
                            fontFamily: 'Poppins-Bold',
                            textAlign: 'center'
                          }}
                        >
                          MISSION
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        style={{
                          background: '#FFFFC8'
                        }}
                      >
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 20.8,
                            fontFamily: 'Poppins-Bold',
                            textAlign: 'justify',
                            paddingLeft: 50,
                            paddingRight: 50
                          }}
                        >
                          Simplify the purchasing workflow of businesses by
                          providing uncomplicated and no-fuss PRO-curement
                          digital solutions that advance both buyers’ and
                          sellers’ targets and welfare.
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid xs={12} lg={5} container alignItems="center">
                      <Grid
                        xs={12}
                        style={{
                          background:
                            'linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #FFFFC8 50%, #FFFFC8 100%)'
                        }}
                      >
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 58,
                            fontWeight: 600,
                            fontFamily: 'Poppins-Bold',
                            textAlign: 'center'
                          }}
                        >
                          VISION
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        style={{
                          background: '#FFFFC8'
                        }}
                      >
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 20.8,
                            fontFamily: 'Poppins-Bold',
                            textAlign: 'justify',
                            paddingLeft: 50,
                            paddingRight: 50
                          }}
                        >
                          Be the best-in-class in delivering unmatched
                          purchasing & procurement solutions, enabling optimum
                          business productivity, profitability, and further
                          growth.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12} container style={{paddingBottom: 50}}>
                  <Grid xs={12} container justify="space-between">
                    <Grid xs={12} lg={4} container alignItems="center">
                      <Grid xs={12} style={{ textAlign: 'center' }}>
                        <img
                          src={WHY_PARTNER_WITH_US}
                          style={{ height: '100%', objectFit: 'contain' }}
                        />
                      </Grid>
                    </Grid>

                    <Grid xs={12} lg={8} container alignItems="center">
                      <Grid xs={12}>
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 25,
                            fontFamily: 'Poppins-Medium',
                            textAlign: 'left',
                            fontWeight: 500
                          }}
                        >
                          <span
                            style={{
                              background: '#FFFFC8'
                            }}
                          >
                            It’s like having a support team at a fraction of the
                            cost.
                          </span>
                        </Typography>
                      </Grid>

                      <Grid xs={12}>
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 20,
                            fontFamily: 'Poppins',
                            textAlign: 'left',
                            fontWeight: 500
                          }}
                        >
                          Vendor Bay can be your company’s outsourced purchasing
                          arm or the extension of your in-house procurement
                          team. Imagine the business productivity this could
                          bring at minimal to practically zero capital
                          expenditure costs.
                        </Typography>
                      </Grid>

                      <Grid xs={12}>
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 25,
                            fontFamily: 'Poppins-Medium',
                            textAlign: 'left',
                            fontWeight: 500
                          }}
                        >
                          <span
                            style={{
                              background: '#FFFFC8'
                            }}
                          >
                            Another platform to promote and reach your market.
                          </span>
                        </Typography>
                      </Grid>

                      <Grid xs={12}>
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 20,
                            fontFamily: 'Poppins',
                            textAlign: 'left',
                            fontWeight: 500
                          }}
                        >
                          Vendor Bay helps suppliers worldwide reach their
                          clientele more cost-efficiently. Being on the web,
                          this increases your opportunities to get your name out
                          there, and to buy and sell tou your targeted markets.
                        </Typography>
                      </Grid>

                      <Grid xs={12}>
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 25,
                            fontFamily: 'Poppins-Medium',
                            textAlign: 'left',
                            fontWeight: 500
                          }}
                        >
                          <span
                            style={{
                              background: '#FFFFC8'
                            }}
                          >
                            Uncomplicated technology coupled with professional
                            account support.
                          </span>
                        </Typography>
                      </Grid>

                      <Grid xs={12}>
                        <Typography
                          style={{
                            color: 'rgb(34, 77, 107)',
                            fontSize: 20,
                            fontFamily: 'Poppins',
                            textAlign: 'left',
                            fontWeight: 500
                          }}
                        >
                          Vendor Bay’s easy-to-understand web interface allows
                          you to achieve your business goals in no time. In
                          addition, you can work and consult with Vendor Bay’s
                          team of project managers in simplifying your business
                          purchasing needs.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default AboutUspage;

import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Paper,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Divider,
  SvgIcon
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import StepConnector, {
  StepConnectorClasskey
} from '@material-ui/core/StepConnector';
import STEPPER_ONE_ICON from '../../assets/register/STEPPER_1_ICON.svg';
import Page from 'src/components/Page';
import Background1 from '../../assets/register/Signup_Banner.png';
import { useRegister } from './register.hooks';
import { useFirebase } from 'react-redux-firebase';
import GET_MY_ACCOUNT_CERTIFIED_IMAGE from '../../assets/register/GET_MY_ACCOUNT_CERTIFIED.png';
import START_SELLING_TO_CORPORATE_BUYERS_IMAGE from '../../assets/register/START_SELLING_TO_CORPORATE_BUYERS_IMAGE.png';
import RegistrationStepper from '../../components/RegistrationStepper';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    // backgroundImage: `url(require("src/assets/login/Login_Banner.png"))`,
    background: `url(${Background1})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(10, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#9FC6DD',
    color: 'white'
  },
  checkBox: {
    margin: theme.spacing(3, 0, 2)
  },
  textField: {
    background: 'white'
    // margin: theme.spacing(2, 0, 1),
  },
  divider: {
    width: '100%',
    backgroundColor: '#D8D8D8'
  },
  createAccountText: {
    color: '#9FC6DD'
  },
  button: {
    margin: theme.spacing(3, 0, 2)
  },
  welcome: {
    fontSize: 93.6,
    fontFamily: 'Poppins-Bold',
    textAlign: 'center',
    color: '#2f4e6b',
    paddingTop: 100
  },
  welcomeSubtitle: {
    fontSize: 40.11,
    fontFamily: 'Poppins-Medium',
    textAlign: 'center',
    color: '#2f4e6b',
    paddingTop: 10
  },
  createMyVendorBayAccount: {
    fontSize: 33.33,
    fontFamily: 'Poppins-Bold',
    textAlign: 'left',
    color: '#2f4e6b',
    paddingTop: 10,
    paddingLeft: 30
  },
  dots: {
    fontSize: 50,
    fontFamily: 'Poppins-Bold',
    textAlign: 'left',
    color: '#2f4e6b',
    paddingTop: 10
  },
  signUpText: {
    fontFamily: 'Poppins-Light',
    fontSize: 25,
    fontWeight: 'bold',
    color: '#2f4e6b'
  },
  connectorLine: {
    borderTopWidth: 20
  }
}));

const RegisterView = () => {
  const firebase = useFirebase();
  const classes = useStyles();
  const navigate = useNavigate();
  const { inputs, handleSubmit, handleInputChange } = useRegister({ navigate });

  return (
    <Page className={classes.root} title="Register">
      <Grid container component="main" className={classes.root}>
        <Grid container justify="center" xs={12}>
          <Grid xs={12}>
            <Typography className={classes.welcome}>Welcome</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography className={classes.welcomeSubtitle}>
              Enter your details and start journey with us.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          elevation={6}
          square
          style={{
            paddingLeft: 55,
            marginTop: 152,
            border: 'rgb(240, 243, 244)',
            borderWidth: 1,
            borderStyle: 'solid',
            marginRight: 33,
            marginLeft: 33,
            backgroundColor: 'rgb(240, 243, 244)'
          }}
        >
          <Typography display="inline" className={classes.dots}>
            ● ● ●
          </Typography>
          <Typography
            display="inline"
            className={classes.createMyVendorBayAccount}
          >
            CREATE MY VENDORBAY ACCOUNT
          </Typography>
        </Grid>

        <RegistrationStepper activeStep={0}/>
        <Grid
          container
          justify="center"
          xs={12}
          style={{
            border: 'rgb(240, 243, 244)',
            borderWidth: 1,
            borderStyle: 'solid',
            marginRight: 33,
            marginLeft: 33
          }}
        >
          <Grid item xs={12} sm={8} md={4} elevation={6} square>
            <div className={classes.paper}>
              <Typography className={classes.signUpText}>SIGN UP</Typography>
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="firstName"
                autoFocus
                InputProps={{
                  className: classes.input
                }}
                onChange={handleInputChange}
                value={inputs.firstName}
              />
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
                autoFocus
                InputProps={{
                  className: classes.input
                }}
                onChange={handleInputChange}
                value={inputs.lastName}
              />
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputProps={{
                  className: classes.input
                }}
                onChange={handleInputChange}
                value={inputs.email}
              />
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleInputChange}
                value={inputs.password}
              />
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                onChange={handleInputChange}
                value={inputs.confirmPassword}
              />
              <Grid container alignContent={'flex-start'}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="I agree with terms and condition"
                  className={classes.checkBox}
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="#9FC6DD"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign Up
              </Button>
              <Grid container alignItems="center" justify="center">
                <Typography
                  variant="body2"
                  className={classes.createAccountText}
                >
                  Already have an account?
                </Typography>
                <Button size="small">
                  <Typography
                    variant="body2"
                    className={classes.createAccountText}
                    onClick={() => navigate('/login')}
                  >
                    Sign In
                  </Typography>
                </Button>
              </Grid>
              <Box mt={5} />
            </div>
          </Grid>

          <Grid item xs={12} sm={8} md={4} elevation={6} square>
            <div className={classes.paper}>
              <Typography className={classes.signUpText}>
                GET MY ACCOUNT CERTIFIED
              </Typography>
              <Box>
                <img
                  src={GET_MY_ACCOUNT_CERTIFIED_IMAGE}
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    paddingTop: 79
                  }}
                />
              </Box>
            </div>
          </Grid>

          <Grid item xs={12} sm={8} md={4} elevation={6} square>
            <div className={classes.paper}>
              <Typography className={classes.signUpText}>
                START SELLING TO CORPORATE BUYERS
              </Typography>
              <Box>
                <img
                  src={START_SELLING_TO_CORPORATE_BUYERS_IMAGE}
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    paddingTop: 79
                  }}
                />
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default RegisterView;
